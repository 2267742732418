import { faFileAlt, faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { faFileWord } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DocumentThumbnail = ({ file }) => {
    const getIcon = (extension) => {
        switch (extension) {
            case 'docx':
                return <FontAwesomeIcon icon={faFileWord} />;
            case 'xlsx':
                return <FontAwesomeIcon icon={faFileExcel} />;
            default:
                return <FontAwesomeIcon icon={faFileAlt} />;
        }
    };

    const extension = file.name.split('.').pop();
    const icon = getIcon(extension);

    return <img src={icon} alt={`${extension} thumbnail`} style={{ width: 100, height: 100 }} />;
};
