import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

// shared
export const automationEndPoint = "https://intranx.com:3500/getcompanydetail/";
export const liveStatusEndPoint = "https://intranx.com:3500";
export const baseURL = "http://134.209.22.223";


//==============================> Development env

// const graphqlEndpoint =  "http://localhost:5000/graphql";
// const graphqlWsEndpoint = "ws://localhost:5000/graphql";

//   const httpLink = createHttpLink({
//     uri: graphqlEndpoint,
//   });

//   // Create a WebSocket link for subscriptions
//   const wsLink = new GraphQLWsLink(createClient({
//     url: graphqlWsEndpoint,
//     on: {
//       connected: () => console.log('WebSocket connected'),
//       closed: () => console.log('WebSocket closed'),
//       error: (err) => console.error('WebSocket error', err),
//     },
//   }));


//   const authLink = setContext((_, { headers }) => {
//     // get the authentication token from local storage if it exists
//     const token = localStorage.getItem('authToken');
//     // return the headers to the context so httpLink can read them
//     return {
//       headers: {
//         ...headers,
//         authorization: token ? `Bearer ${token}` : "",
//         credentials: 'include',
//       },
//     }
//   });

//   const splitLink = split(
//     ({ query }) => {
//       const definition = getMainDefinition(query);
//       return (
//         definition.kind === 'OperationDefinition' &&
//         definition.operation === 'subscription'
//       );
//     },
//     wsLink,
//     authLink.concat(httpLink)
//   );

//   export const privateClient = new ApolloClient({
//     // link: authLink.concat(httpLink),
//     link: splitLink,
//     cache: new InMemoryCache()
//   });


//   export const publicClient = new ApolloClient({
    
//     uri: graphqlEndpoint,
    
//     cache: new InMemoryCache(),

//   });


//===========================> Production env



const graphqlEndpoint =  "https://www.widedownloader.com/graphql"; 
const graphqlWsEndpoint = "wss://www.widedownloader.com/graphql";

const httpLink = createHttpLink({
  uri: graphqlEndpoint,
  credentials: 'include', 
});

// Create a WebSocket link for subscriptions
const wsLink = new GraphQLWsLink(createClient({
  url: graphqlWsEndpoint,
  on: {
    connected: () => console.log('WebSocket connected'),
    closed: () => console.log('WebSocket closed'),
    error: (err) => console.error('WebSocket error', err),
  },
}));


const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('authToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      // credentials: 'include',
    },
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

export const privateClient = new ApolloClient({
  // link: authLink.concat(httpLink),
  link: splitLink,
  cache: new InMemoryCache()
});


export const publicClient = new ApolloClient({
  
  uri: graphqlEndpoint,
  
  cache: new InMemoryCache(),

});