import "./schedule.css";
import dayjs from "dayjs";
import Spinner from "@src/components/spinner";
import WordLetterAvatar from "@src/components/avatar";
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Avatar, DatePicker, Input, Modal, Popover, Select } from "antd";
import { faArrowsSpin, faBackward, faBackwardFast, faClose, faEllipsisVertical, faForward, faForwardFast, faLocationDot, faPencil, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { AddDutyModal } from "./addDuty/addDuty.model";
import { getSitesQuery } from "@src/util/query/site.query";
import { useMutation, useQuery } from "@apollo/client";
import { getEmployeeByBranchId } from "@src/util/query/employee.query";
import { ScheduleQuery } from "@src/util/query/schedule.query";
import { ScheduleStatusType } from "@src/util/types/scheduleStatus";
import { UpdateScheduleByField } from "@src/util/mutation/schedule.mutation";
import { EditDutyModal } from "./editDutyModal/editDuty.modal";
import { getEmployeeForTrainedUnTrained } from "@src/util/query/employee.query";
import { useNavigate } from "react-router-dom";
import { deleteScheduleByScheduleIdMutation } from "@src/util/mutation/schedule.mutation";
import { CancelDutyModal } from "./cancelDuty/cancelDuty.modal";
import { getUkHolidays } from "@src/util/ukholidays/holidays";



// status 
// open == 0
// confirm == 1
// unconfirm Assigned Emp == 2

export const SchedulePage = ({siteId})=>{
    const {Option} = Select;

    const [dateRange, setDateRange] = useState([]);

    const handelDateChange = (date, dateString)=>{
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
    };

    useEffect(()=>{
        const dateString = dayjs();
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
            dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
    }, []);

    const nextWeek = ()=>{
        const dateString = dayjs(dateRange[0]).add(1, 'week');
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
    }

    const previousWeek = ()=>{
        const dateString = dayjs(dateRange[0]).subtract(1, 'week');
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
    }

    const tableRef = useRef();

    const handelCellMouseEnter = (cell)=>{
        cell?.children[0]?.classList.add('hide');
        cell?.children[1]?.classList.remove('hide');
    };

    const handelCellMouseLeave = (cell)=>{
        cell?.children[0]?.classList.remove('hide');
        cell?.children[1]?.classList.add('hide');
    };

    const [dutyModal, setDutyModal] = useState({});
    const [warningModal, setWarningModal] = useState(false);

    // get site list
    const {data: siteData, loading: siteLoading, refetch} = useQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        },
        fetchPolicy:'cache-and-network'
    });


    const [site, setSite] = useState({});
    const [siteAreas, setSiteAreas] = useState([]);
    const [selectedSiteAreas, setSelectedSiteAreas] = useState([]);
    const [siteScheduleAreas, setSiteScheduleAreas] = useState([]);

    
    useEffect(()=>{
        if(siteId && siteData?.sites?.length>0){
            setSite(siteData?.sites?.find((site)=>site?._id==siteId));
        }else if(siteId==undefined && siteData?.sites?.length>0 && localStorage.getItem("scheduledSite")){
            setSite(siteData?.sites?.find((site)=>site?._id==JSON.parse(localStorage.getItem("scheduledSite"))?._id));   
        }
    },[siteId, siteData?.sites]);

    // setSite For already selected site
    useEffect(()=>{
        if(localStorage.getItem("scheduledSite")){
            refetch();
        }
    },[localStorage.getItem("scheduledSite"), siteId]);

    
    useEffect(()=>{
        if(site && Object.keys(site)?.length>0){
            const siteArea = siteData?.sites?.filter((remotesite)=>site._id===remotesite._id)?.map((site)=>site?.siteArea);
            if(siteArea?.length>0){
                setSiteAreas([...siteArea]);
            }
        }
    },[site, siteData?.sites]);

    
    const {data: scheduleData, loading: scheduleDataLoading, refetch: scheduleRefetch} = useQuery(ScheduleQuery,{
        variables:{
            siteId: site?._id
        },
        skip: !site?._id,
        fetchPolicy: 'cache-and-network'
    });
    
    useEffect(()=>{
        if(Object.keys(site)?.length>0){
            if(selectedSiteAreas?.length>0){
                const areas = siteData?.sites?.filter((remotesite)=>site._id===remotesite._id)?.map((site)=>site?.siteArea)[0]?.filter((area)=>selectedSiteAreas.includes(area._id));
                const areaWiseJob = areas?.map((area)=>{
                    const jobs = scheduleData?.getScheduleBySiteId?.response;
                    const isAreaJobExist = jobs?.filter((job)=>job.siteAreaId===area?._id);
                    if(isAreaJobExist?.length>0){
                        return {
                            ...area,
                            jobs: isAreaJobExist
                        }
                    }else{
                        return area;
                    }
                });
                setSiteScheduleAreas(areaWiseJob)
            }else{
                const areas = siteData?.sites?.filter((remotesite)=>site._id===remotesite._id)?.map((site)=>site?.siteArea)[0];
                const jobs = scheduleData?.getScheduleBySiteId?.response;
                const areaWiseJob = areas?.map((area)=>{
                    const isAreaJobExist = jobs?.filter((job)=>job.siteAreaId==area?._id);
                    if(isAreaJobExist?.length>0){
                        return {
                            ...area,
                            jobs: isAreaJobExist
                        }
                    }else{
                        return area;
                    }
                });
                setSiteScheduleAreas(areaWiseJob);
            }
        }else{
            setSiteScheduleAreas([]);
        }
    },[selectedSiteAreas, site, scheduleDataLoading, scheduleData]);


    useEffect(()=>{
        const rows = document.querySelectorAll(".addDuty");
        rows.forEach((row)=>{
            row.addEventListener('mouseenter', () => handelCellMouseEnter(row));
            row.addEventListener('mouseleave', () => handelCellMouseLeave(row));        
        });
    },[dateRange, site, siteScheduleAreas]);

    const {data: employeeData, loading: employeeDataLoading} = useQuery(getEmployeeByBranchId,{
        variables:{
            branchId: site?.branchDetail?._id
        },
        fetchPolicy: 'cache-and-network',
        skip: !site?.branchDetail?._id
    });


    // update schedule By Field 
    const [updateSchedule, {loading:updateScheduleLoading}] = useMutation(UpdateScheduleByField);

    const handelUpdateSchedule = async (_id, field, value)=>{
        await updateSchedule({
            variables:{
                input:{
                    _id, field, value
                }
            }
        });
        // if(field=="isPublished" && value===true)
        // await updateSchedule({
        //     variables:{
        //         input:{
        //             _id, field:"status", value: 3
        //         }
        //     }
        // });
        await scheduleRefetch();
    };

    const [editModalData, setEditModalData] = useState({});

    const navigation = useNavigate();

    const [hoveredJob, setHoveredJob] = useState("");
    useEffect(()=>{
        return ()=>{
            setHoveredJob("");
        }
    },[]);

    // get trained employee for left sidebar
    
    const {data: trainedUnTrainedData, loading: trainUnTrainedLoading, refetch: trainUnTrainedRefetch} = useQuery(getEmployeeForTrainedUnTrained,{
        variables:{
            input:{
                siteId: site?._id,
            }
        },
        fetchPolicy:'network-only'
    });

    const [trainedEmpSite, setTrainedEmpSite] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(()=>{
        setTrainedEmpSite(trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response?.filter((emp)=>emp?.firstname?.toLowerCase()?.includes(search.toLowerCase())));
    },[search, trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response]);

    useEffect(()=>{
        if(trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response){
            setTrainedEmpSite([...trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response]);
        }
    },[trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response]);


    
    const [deleteSchedule, {loading:deleteScheduleLoading}] = useMutation(deleteScheduleByScheduleIdMutation);

    // handel cancel duty
    const [cancelledDuty, setCancelledDuty] = useState({});

    const handelDutyCancel = (job)=>{
        setCancelledDuty(job);
        console.log(job, "cancelled job");

    }

    const [bankHoliday, setBankHoliday] = useState([]);
    const getHolidays = async()=>{
      const holiday = await getUkHolidays();
      setBankHoliday(holiday?.englandWales)
    }
  
    useEffect(()=>{
      getHolidays();
    },[]);
    

    return (
        <React.Fragment>

            <div className="main-schedule">

                {/* employee search section */}
                <div className="schedule-emp" >
                    <div className="shift-publisher">
                        <div style={{fontSize:'1.17em', fontWeight:'bold'}}> No Shifts </div>   
                        <div className="text" style={{marginBottom:'0'}}>Add shift to publish</div>             
                    </div>
                    <div className="mt16 emp-list-section">
                        <div className="emp-title">
                            <span>Employees</span>
                        {/* <span className="leftPaneLeftTab leftPaneActiveTab">Employees</span> 
                        <span className="leftPaneRightTab">Archived Employees</span>  */}
                        </div>
                        <div className="emp-list">
                            <Input 
                                type="search"
                                className="generic-input-control"
                                placeholder="Search Employees"
                                style={{fontSize:'14px'}}
                                onChange={(e)=>setSearch(e.target.value)}
                                value={search}
                                suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                            />

                            {!employeeDataLoading? 
                                trainedEmpSite?.map((emp)=>(
                                    <div className="employeeProfile">
                                        {emp?.metadata?.imageURL?
                                        <Avatar size={40} src={emp?.metadata?.imageURL} />
                                        :
                                        <WordLetterAvatar word={emp?.firstname+" "+(emp?.lastname || "")}/>
                                        }
                                        <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
                                            <span style={{fontSize:'13px'}}>{emp.firstname+" "+(emp.lastname || "")}</span>
                                            <span style={{fontSize:'10px'}}>{emp?.metadata?.employmenttype}</span>
                                        </div>
                                    </div>
                                ))
                                :
                                <Spinner/>
                            }
                        </div>
                    </div>
                </div>



                {/* schedule section */}
                <div className="schedule" >

                    <div className="schedule-description" style={{marginLeft:'5px'}}>
                        {/* left section */}
                        <div className="schedule-description-left">
                            
                            <div>
                                <div>Site</div>
                                <span onClick={()=>navigation("/user/site-detail/"+site?._id)}>{site?.sitename || "--"}</span>
                            </div>
                            
                            <div>
                                <div>Site Group</div>
                                <span  onClick={()=>navigation("/user/sitegroup-detail/"+site?.sitegroupDetail?._id)}>{site?.sitegroupDetail?.sitegroupname || "--"}</span>
                            </div>
                            
                            <div>
                                <div>Customer</div>
                                <span onClick={()=>navigation("/user/customer-detail/"+site?.customerDetail?._id)}>{site?.customerDetail?.customername || "--"}</span>
                            </div>
                            
                            <div>
                                <div>Branch</div>
                                <span onClick={()=>navigation("/user/branch-detail/"+site?.branchDetail?._id)}>{site?.branchDetail?.branchname || "--"}</span>
                            </div>

                        </div>

                        {/* right section */}
                        <div className="schedule-description-right">
                            
                            <div style={{display:'flex', gap:'20px'}}>
                                <div className="schedule-item">
                                    <div>0</div>
                                    <div>Billed</div>
                                </div>
                                <div className="schedule-item-divider"></div>
                                <div className="schedule-item">
                                    <div>0</div>
                                    <div>Paid</div>
                                </div>
                            </div>

                            <div style={{display:'flex', gap:'20px'}}>
                                <div  className="schedule-item">
                                    <div>0</div>
                                    <div>Contracted</div>
                                </div>

                                <div className="schedule-item-divider"></div>

                                <div className="schedule-item">
                                    <div>0</div>
                                    <div>Current</div>
                                </div>

                                <div className="schedule-item-divider"></div>


                                <div  className="schedule-item">
                                    <div>0</div>
                                    <div>Differences</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="schedule-section" style={{marginLeft:'5px'}}>

                        <div style={{display:'flex', gap:'20px', alignItems: 'center', width:'30%'}}>

                            <div style={{display:'flex', gap:'15px', width:'108% ', alignItems:'center'}}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    className="custom-select"
                                    style={{width:'100%'}}
                                    placeholder="Select site"
                                    loading={siteLoading || scheduleDataLoading}
                                    suffixIcon={<div className="dropdowncaret"></div>}
                                    value={Object.keys(site)?.length > 0 && siteData?.sites?.length > 0? JSON.stringify(site) : null}
                                    onChange={(e)=>{setSite(JSON.parse(e)); localStorage.setItem("scheduledSite", e)}}
                                >
                                    {siteData?.sites?.map((site)=>(
                                        <Option value={JSON.stringify(site)}>{site?.sitename}</Option>
                                    ))}
                                </Select>
                                <FontAwesomeIcon  icon={faLocationDot} />
                            </div>

                            <Select
                                showSearch
                                optionFilterProp="children"
                                mode="multiple"
                                className="custom-select schedule-siteArea"
                                style={{width:'100%', background:'transparent'}}
                                placeholder="Select site area"
                                suffixIcon={null}
                                disabled={siteAreas?.length>0? false : true}
                                // value={selectedSiteAreas?.length>0? selectedSiteAreas: []}
                                onChange={(e)=>setSelectedSiteAreas(e)}
                            >
                                {
                                    siteAreas?.length>0 && siteAreas?.map((area)=>(
                                        <Option value={area?._id} label={area?.areaname}>{area?.areaname}</Option>
                                    ))
                                }
                            </Select>

                        </div>
                        
                        <div style={{display:'flex', gap:'15px', alignItems: 'center', paddingRight:'16px'}}>
                            
                            <div style={{display:'flex', gap:'5px', alignItems:'center'}}>
                                <FontAwesomeIcon className="icon-bound" icon={faBackwardFast}/>
                                <FontAwesomeIcon className="icon-bound" onClick={previousWeek} icon={faBackward} />
                                <div style={{display:'inline-block', position:'relative'}}>
                                    <Input 
                                        className="generic-input-control"
                                        style={{width:'100%', textAlign:'center'}}
                                        value={dateRange?.length>0? dayjs(dateRange[0]).format('DD MMM') +" - "+ dayjs(dateRange[dateRange?.length-1]).format('DD MMM, YYYY') : null}
                                        readOnly
                                    />
                                    <div style={{position:'absolute', top:'0', width:'100%', zIndex:'1', opacity:'0'}}>
                                        <DatePicker className="generic-input-control" onChange={handelDateChange} />
                                    </div>
                                </div>
                                <FontAwesomeIcon className="icon-bound" onClick={nextWeek} icon={faForward} />
                                <FontAwesomeIcon className="icon-bound" icon={faForwardFast} />
                            </div>

                            <div className="icon-bound" onClick={()=>handelDateChange(null, dayjs())}>Today</div>
                            <div className="icon-bound">
                                <FontAwesomeIcon icon={faArrowsSpin} />
                            </div>

                        </div>

                    </div>

{/* schedule view table for shift display */}

                    <div className="schedule-view">
                        <table style={{width:'-webkit-fill-available'}} cellSpacing={0} ref={tableRef}  className="general-border">
                            <thead>
                                <tr>
                                    {
                                        dateRange?.map((date)=>(
                                            <th style={dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY")?{background: 'lightyellow'}:{}}>
                                                {dayjs(date)?.format("ddd, MMM") +" "+ dayjs(date)?.format("DD")} 
                                                
                                                {
                                                // if the date is today and there is bank holiday on same date
                                                dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY") && bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY")) ? <Popover content={bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?.title}><sub>&nbsp;(Today & BH)</sub></Popover> 
                                                :
                                                // if there is only bank holiday
                                                bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?
                                                <Popover content={bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?.title}>
                                                    <sub>&nbsp;(BH {bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?.title})</sub>
                                                </Popover>
                                                :
                                                // if the date is today date
                                                dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY")?
                                                <sub>&nbsp;(Today)</sub> 
                                                : null}
                                            </th>
                                        ))
                                    }
                                </tr>
                            </thead>

                        {!siteLoading || !scheduleDataLoading || !employeeDataLoading ? siteScheduleAreas?.map((area)=>(
                            <>
                                    <tr>
                                        <td colSpan={7} className="area">
                                            {area?.areaname}
                                        </td>
                                    </tr>
                                    <tr>
                                        {dateRange?.map((date, index)=>{
                                        return(
                                        area?.jobs?.find((job)=>dayjs(job?.date,"DD/MM/YYYY").format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"))?
                                        <td style={dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY")?{background: 'lightyellow'}:{}}>

                                            {area?.jobs?.map((job)=>(
                                            dayjs(job?.date, "DD/MM/YYYY").format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY") ?
                                            <div className={job?.isDutyCancel? "dutyDetail cancelDuty" : "dutyDetail"}
                                                onMouseEnter={()=>setHoveredJob(job?._id)} onMouseLeave={()=>setHoveredJob("")}
                                            >

                                                <div style={{display:'flex', justifyContent:'space-between'}}>

                                                {job?.assignedEmployeeDetail?

                                                        <Avatar.Group
                                                            maxCount={1}
                                                            maxStyle={{
                                                            color: '#f56a00',
                                                            backgroundColor: '#fde3cf',
                                                            }}
                                                            style={{width:'55px'}}
                                                        >
                                                            {
                                                            job?.assignedEmployeeDetail?.metadata?.imageURL?
                                                                <Avatar size={40} src={job?.assignedEmployeeDetail?.metadata?.imageURL} />    

                                                            :
                                                                <Avatar size={40}> {job?.assignedEmployeeDetail?.firstname?  job?.assignedEmployeeDetail?.firstname[0]+(job?.assignedEmployeeDetail?.lastname? job?.assignedEmployeeDetail?.lastname[0] : "") : ""} </Avatar>    
                                                            }  
                                                        </Avatar.Group>    
                                                         
                                                        :   
                                                    
  
                                                        job?.assignedAgencyDetail?
                                                    
                                                        <Avatar.Group
                                                            maxCount={1}
                                                            maxStyle={{
                                                            color: '#f56a00',
                                                            backgroundColor: '#fde3cf',
                                                            }}
                                                            style={{
                                                                width:'55px'}}
                                                        >
                                                            <Avatar size={40} title={job?.assignedAgencyDetail?.agencyname}> {job?.assignedAgencyDetail?.agencyname[0]+job?.assignedAgencyDetail?.agencyname[job?.assignedAgencyDetail?.agencyname?.length-1]} </Avatar>                                                        

                                                        </Avatar.Group>
                                                        : null  
                                                    
                                                }
                                                    <div style={{display:'flex', gap:'1px', flexDirection:'column', width:'-webkit-fill-available'}}>
                                                        {/* duty time and schedule status */}
                                                        <div className="duty-time">
                                                            {/* shift time */}
                                                            <div className="schedule-time">
                                                                { 
                                                                    
                                                                    job?.shiftStartTime
                                                                    
                                                                    + "-" + 
                                                
                                                                    job?.shiftEndTime

                                                                }
                                                            </div>

                                                            {/* status */}
                                                            <div className={job?.status==0?'openDuty schedule-status':job?.status==1?'confirmDuty schedule-status':job?.status==2?'unConfirmDuty schedule-status':"completedDuty schedule-status"}>
                                                                {ScheduleStatusType[job?.status]}
                                                                {/* unconfirmed */}
                                                            </div>
                                                        </div> 

                                                        {/* job type */}
                                                        <div className="schedule-dutyType">{job?.hourTypeDetail[0]?.name}, {job?.dutyTypeDetail[0]?.code}</div> 

                                                        {/* condition to render either agency name or employee or both */}
                                                        {job?.assignedEmployeeDetail?
                                                            
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'-5px'}} className={"schedule-detail-wrap"}>
                                                                    <div className="schedule-name">{
                                                                    job?._id==hoveredJob && (job?.assignedEmployeeDetail.firstname+" "+job?.assignedEmployeeDetail.lastname)?.length>17? 
                                                                        job?.assignedEmployeeDetail.firstname 
                                                                    : 
                                                                        job?.assignedEmployeeDetail.firstname+" "+(job?.assignedEmployeeDetail.lastname || "")
                                                                    }</div>
                                                                    <div className="control-icon">
                                                                        <div className="controlledIcon" onClick={()=>setEditModalData({jobId: job?._id, ...site, selectedarea:area })}><FontAwesomeIcon style={{fontSize:'10px'}}  icon={faPencil}/></div> 
                                                                        <Popover
                                                                            placement="right"
                                                                            trigger={"click"}
                                                                            overlayClassName='settingCustomPopover'
                                                                            content={
                                                                                <>
                                                                                {job?.hasOwnProperty("isPublished") && job?.isPublished==true && job.status==3? null:
                                                                                        <>
                                                                                            {job.status==0? null:
                                                                                            <>
                                                                                                <div className="popoverdataitem" onClick={()=>handelUpdateSchedule(job?._id, "isPublished", job?.isPublished? false: true)}>{job?.isPublished?"Unpublish":"Publish"}</div>
                                                                                                <div className="popoverdataitem" onClick={()=>handelUpdateSchedule(job?._id, "status", job?.status==1? 2: 1)}>{job?.status==1?"Unconfirm":"Confirm"}</div>
                                                                                            </>
                                                                                            }
                                                                                            {job?.isPublished!==true && job?.status==0? <div className="popoverdataitem">Schedule Assist</div> : null}
                                                                                        
                                                                                        </>
                                                                                
                                                                                }    
                                                                                    <div className="popoverdataitem" onClick={async ()=>{
                                                                                        if(job?.status=="1"){
                                                                                            handelDutyCancel(job)

                                                                                        }else{
                                                                                            await deleteSchedule({
                                                                                                variables: {
                                                                                                    scheduleId: job?._id
                                                                                                }
                                                                                            });
                                                                                            await scheduleRefetch();
                                                                                        }
                                                                                    }}>{job?.status=="1"? "Cancel Duty" : "Delete Duty"}</div>
                                                                                </>
                                                                            }
                                                                        >
                                                                            {
                                                                                job?.isDutyCancel?
                                                                                null:
                                                                                <div className="controlledIcon"><FontAwesomeIcon style={{fontSize:'12px'}}  icon={faEllipsisVertical}/></div> 

                                                                            }
                                                                        </Popover>
                                                                    </div> 
                                                                </div>

                                                            :job?.assignedAgencyDetail?
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'-5px'}}>
                                                                    <div className="schedule-name">{job?.assignedAgencyDetail.agencyname}</div>
                                                                    <div className="control-icon">
                                                                    <div className="controlledIcon" onClick={()=>setEditModalData({jobId: job?._id, ...site, selectedarea:area })}><FontAwesomeIcon style={{fontSize:'10px'}}  icon={faPencil}/></div> 
                                                                    <Popover
                                                                        placement="right"
                                                                        trigger={"click"}
                                                                        overlayClassName='settingCustomPopover'
                                                                        content={
                                                                            <>
                                                                            {job?.hasOwnProperty("isPublished") && job?.isPublished==true && job.status==3? null:
                                                                                    <>
                                                                                        {job.status==0? null:
                                                                                        <>
                                                                                            <div className="popoverdataitem" onClick={()=>handelUpdateSchedule(job?._id, "isPublished", job?.isPublished? false: true)}>{job?.isPublished?"Unpublish":"Publish"}</div>
                                                                                            <div className="popoverdataitem" onClick={()=>handelUpdateSchedule(job?._id, "status", job?.status==1? 2: 1)}>{job?.status==1?"Unconfirm":"Confirm"}</div>
                                                                                        </>
                                                                                        }
                                                                                        {job?.isPublished!==true && job?.status==0? <div className="popoverdataitem">Schedule Assist</div> : null}
                                                                                    
                                                                                    </>
                                                                            
                                                                            }    
                                                                                <div className="popoverdataitem" onClick={async ()=>{
                                                                                        handelDutyCancel(job)
                                                                                }}>Cancel Duty</div>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <div className="controlledIcon"><FontAwesomeIcon style={{fontSize:'12px'}}  icon={faEllipsisVertical}/></div> 
                                                                    </Popover>
                                                                    </div> 
                                                                </div>
                                                            :
                                                                // this section is when duty is open and nott assigned to any one
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'-5px'}}>
                                                                    <div className="schedule-name" style={{visibility:'hidden'}}>xyz</div>
                                                                    <div className="control-icon">
                                                                    <div className="controlledIcon" onClick={()=>setEditModalData({jobId: job?._id, ...site, selectedarea:area })}><FontAwesomeIcon style={{fontSize:'10px'}}  icon={faPencil}/></div> 
                                                                    <Popover
                                                                        placement="right"
                                                                        trigger={"click"}
                                                                        overlayClassName='settingCustomPopover'
                                                                        content={
                                                                            <>
                                                                            {job?.hasOwnProperty("isPublished") && job?.isPublished==true && job.status==3? null:
                                                                                    <>
                                                                                        {job.status==0? null:
                                                                                        <>
                                                                                            <div className="popoverdataitem" onClick={()=>handelUpdateSchedule(job?._id, "isPublished", job?.isPublished? false: true)}>{job?.isPublished?"Unpublish":"Publish"}</div>
                                                                                            <div className="popoverdataitem" onClick={()=>handelUpdateSchedule(job?._id, "status", job?.status==1? 2: 1)}>{job?.status==1?"Unconfirm":"Confirm"}</div>
                                                                                        </>
                                                                                        }
                                                                                        {job?.isPublished!==true && job?.status==0? <div className="popoverdataitem">Schedule Assist</div> : null}
                                                                                    
                                                                                    </>
                                                                            
                                                                            }    
                                                                                <div className="popoverdataitem" onClick={async ()=>{
                                                                                        await deleteSchedule({
                                                                                            variables: {
                                                                                                scheduleId: job?._id
                                                                                            }
                                                                                        });
                                                                                        await scheduleRefetch();
                                                                                }}>Delete Duty</div>
                                                                            </>
                                                                        }
                                                                    >
                                                                        <div className="controlledIcon"><FontAwesomeIcon style={{fontSize:'12px'}}  icon={faEllipsisVertical}/></div> 
                                                                    </Popover>
                                                                    </div> 
                                                                </div>           
                                                        }  
                                                              
                                                    </div>

                                                </div>

                                                {/* sign to denote particular parties i.e agency or employee */}
                                                <div className="meta-info">
                                                    {job?.assignedAgency?.length>0? 
                                                        <div className="entity">A</div>
                                                        :job?.assignedEmployeeDetail?.metadata?.agency?
                                                        <div className="entity" title="Agency Employee">A</div>
                                                        :
                                                        null
                                                        // job?.assignedEmployeeDetail?.metadata?.agency
                                                    }
                                                    {
                                                        // check if report is mandatory for this job
                                                        job?.reporting?
                                                            // check if job report is submitted
                                                            job?.isReportSubmitted?
                                                                <div className={"entity"}>R</div>
                                                            :
                                                            <Popover
                                                                content={"Report is pending for this job"}
                                                            >
                                                                <div className={"entity reportWarning"}>R</div>
                                                            </Popover>
                                                        :
                                                        null
                                                    }
                                                    
                                                </div>


                                            </div>
                                            :
                                            null
                                            ))}

                                            <div key={index} className="addDuty" style={{borderBottom:'0'}} onClick={()=>{date.isBefore(dayjs()) && date.format("DD/MM/YYYY")!=dayjs().format("DD/MM/YYYY") ? setWarningModal(true): setDutyModal({visible:true, title:date, site, area});}}>
                                                        
                                                <span><FontAwesomeIcon icon={faPlus}/></span>
                                                <span className="hide" style={{fontSize:'13px'}}>Add Duty</span>                                    
                                                
                                            </div>

                                        </td>
                                            :
                                        <td style={dayjs().format('DD/MM/YYYY')==dayjs(date).format("DD/MM/YYYY")?{background: 'lightyellow'}:{}}>          
                                            <div key={index} className="addDuty" style={{borderBottom:'0'}} onClick={()=>{date.isBefore(dayjs()) && date.format("DD/MM/YYYY")!=dayjs().format("DD/MM/YYYY") ? setWarningModal(true): setDutyModal({visible:true, title:date, site, area})}}>
                                                        
                                                <span><FontAwesomeIcon icon={faPlus}/></span>
                                                <span className="hide" style={{fontSize:'13px'}}>Add Duty</span>                                    
                                                
                                            </div>                                                      
                                        </td>
                                        )})}
                                    </tr>
                            </>    
                        )):
                            <tr>
                                <td colSpan={7} style={{textAlign:'center'}}>
                                    <Spinner/>
                                </td>
                            </tr>
                        }
                        </table>
                    </div>

                </div>

            </div>
            {
                dutyModal?.visible?
                <>
                    <AddDutyModal dutyModal={dutyModal} close={()=>{setDutyModal({}); }} 
                        employeeData={employeeData} 
                        employeeDataLoading={employeeDataLoading} 
                        scheduleRefetch={scheduleRefetch}
                    />
                   
                </>
                :null
            }
            {
                Object.keys(editModalData)?.length>0 ?
                <EditDutyModal
                    editData={editModalData}
                    close={()=>setEditModalData({})}
                    refetch={scheduleRefetch}
                />
                :null
            }

            {/* warning model on passed dates */}
            {
                warningModal &&
                <WarningModal 
                    visible={warningModal}
                    close={()=>setWarningModal(false)}
                    message={"Schedule not allowed on passed date"}
                />
            }

            {/* cancel duty modal */}
            {
                // cancelled duty object would be treated as boolean to open and close modal
                cancelledDuty && Object.keys(cancelledDuty)?.length>0?
                <CancelDutyModal
                    visible={true}
                    close={()=>setCancelledDuty({})}
                    cancelledDutyData={cancelledDuty}
                    refetch={scheduleRefetch}
                />
                : null
            }


        </React.Fragment>
    )
};

const WarningModal = ({close, visible, message})=>{
    return(
        <Modal
            visible={visible}
            footer={null}
            closable={false}
        >

        <div className='modal-header-title'>
            <span style={{letterSpacing:'0.2px'}}> Warning </span>
            <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
        </div>

            <div className='modal-body'>
                <Alert type="error" message={message} showIcon/>
            </div>

        </Modal>
    );
}