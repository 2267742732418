import "./addDuty.css";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import { LoadingOutlined, UserOutlined } from "@ant-design/icons"
import { faChevronDown, faChevronUp, faClockRotateLeft, faClose, faDedent, faEllipsisVertical, faLayerGroup, faPersonBooth, faPersonBurst, faPersonDigging, faSearch, faStreetView, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox, Form, Input, InputNumber, Modal, Popover, Select, Avatar, Spin, Tabs, TimePicker, Dropdown, Menu, Switch, Tag } from "antd";
import React, { useEffect, useState, useRef } from "react"
import { shiftTypeQuery } from "@src/util/query/shiftType.query";
import { getPayLevelQuery } from "@src/util/query/paylevel.query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { newScheduleMutation } from "@src/util/mutation/schedule.mutation";
import { useDispatch } from "react-redux";
import Spinner from "@src/components/spinner";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { getReportTemplates } from '@src/util/query/reportTemplate.query';
import { CheckFailureModal } from "../checkFail.model";
import { newScheduleTimesMutation } from "@src/util/mutation/scheduleTimes.mutation";
import { getEmployeeForScheduleSearchQuery, getEmployeeForTrainedUnTrained } from "@src/util/query/employee.query";
import { newScheduleCheckMutation } from "@src/util/mutation/scheduleChecks.mutation";
import { scheduleCheckFailureList } from "@src/util/scheduleCheckFailureList";
import { getUkHolidays } from "@src/util/ukholidays/holidays";




export const AddDutyModal = ({
    dutyModal:{visible=true, title, site, area},
    close, employeeData, employeeDataLoading, scheduleRefetch})=>{
    
    dayjs.extend(utc);
    dayjs.extend(duration);

    const [allowedSkilledForSpecificArea, setAllowedSkillsForSpecificArea] = useState([]);
    useEffect(()=>{
        if(site?.siteskills?.length>0){
            setAllowedSkillsForSpecificArea(site?.siteskills?.filter((areaSkill)=>areaSkill?.areaId==area?._id && areaSkill?.status)?.map((skill)=>skill?.skillId))
        }
    },[site, area]);



    const {Option} = Select;
    const {data: dutyTypeData, loading: dutyTypeLoading, refetchDutyType} = useQuery(getPayLevelQuery,{
        fetchPolicy: 'network-only',
    });
    const {data: hourTypeData, loading: hourTypeDataLoading, refetch: refetchHourType} = useQuery(shiftTypeQuery);

    const [dutyType, setDutyType] = useState("");
    const [hourType, setHourType] = useState("");
    
    const [shiftStartTime, setShiftStartTime] = useState("");
    const [shiftEndTime, setShiftEndTime] = useState("");

    const [unPaidBreakHour, setUnPaidBreakHour] = useState("");
    const [unPaidBreakMintue, setUnPaidBreakMintue] = useState("");

    const [unPaidBillHour, setUnPaidBillHour] = useState("");
    const [unPaidBillMintue, setUnPaidBillMintue] = useState("");

    const [bookingRef, setBookingRef] = useState("");
    const [supplierRef, setSupplierRef] = useState("");

    // assigned employee
    const [assignedEmployee, setAssignedEmployee] = useState();
    const [assignedAgency, setAssignedAgency] = useState();
    
    const [newSchedule, {loading: newScheduleLoading}] = useMutation(newScheduleMutation);
    const [employeeSlider, setEmployeeSlider] = useState(false);

   
    const [shiftTime, setShiftTime] = useState("");
    
    useEffect(()=>{
        if(dutyType?.length>0 && hourType?.length>0 && shiftStartTime?.length>0 ){
            setEmployeeSlider(true);
        }else{
            setEmployeeSlider(false);
        }
    }, [dutyType, hourType, shiftStartTime]);

    const [trainedEmp, setTrainedEmp] = useState([]);
    const [otherEmp, setOtherEmp] = useState([]);

    const [search, setSearch] = useState("");

    const handelSearch = (keyword)=>{
        setSearch(keyword);
    };

    
    const {data: trainedUnTrainedData, loading: trainUnTrainedLoading, refetch: trainUnTrainedRefetch} = useQuery(getEmployeeForTrainedUnTrained,{
        variables:{
            input:{
                siteId: site?._id,
            }
        },
        fetchPolicy:'network-only'
    });



    // get trained employes
    useEffect(()=>{
        if(shiftStartTime && trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response && !trainUnTrainedLoading){
            trainedUnTrainedData?.getEmployeeForTrainedUnTrained?.response?.map(async (emp)=>{
                await setTimeout(()=>{},100);
                checkEmployeeIfSuitable(emp, true);
                await setTimeout(()=>{},100);
            });
        }else{
            setTrainedEmp([]);
            setOtherEmp([]);
        }
    },[shiftStartTime, trainedUnTrainedData]);

    const calculateTimeDifference = (time1, time2) => {
        // Define the start and end times
        const startTime = dayjs(title?.format("DD/MM/YYYY").toString() + " " + time1, 'DD/MM/YYYY HH:mm');
        let endTime = dayjs(title?.format("DD/MM/YYYY").toString() + " " + time2, 'DD/MM/YYYY HH:mm');
    
        // Handle case where endTime is before startTime (next day)
        if (endTime.isBefore(startTime)) {
            endTime = endTime.add(1, 'day');  // Adjust if end time is on the next day
        }
    
        // Calculate the difference in hours and minutes between startTime and endTime
        const diffInMilliseconds = endTime.diff(startTime);
        const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
        const hours = Math.floor(diffInHours);
        const minutes = Math.round((diffInHours - hours) * 60);
    
        // Check if the time period crosses midnight
        const midnight = title.endOf('day');
        const firstHourRaw = endTime.isAfter(midnight) 
            ? midnight.diff(startTime, 'minute') / 60 
            : diffInHours;  // If no cross to next day, use total time
    
        // Adjust firstHour calculation
        const firstHour = firstHourRaw < 0 ? 0 : firstHourRaw;  // Make sure it's not negative
    
        // Prepare the response based on whether it crosses midnight
        const res = {
            firstDate: title?.format("DD/MM/YYYY"),
            secondDate: endTime.isAfter(midnight) ? endTime.format("DD/MM/YYYY") : null,  // If time crosses midnight
            firstDateHours: firstHour.toFixed(2),  // Hours before midnight (or total hours if no cross)
            secondDateHours: endTime.isAfter(midnight) ? (diffInHours - firstHour).toFixed(2) : null,  // Time after midnight, if applicable
            totalHours: hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0'),
            firstHourRaw
        }
    
        return res;
    }
    
    useEffect(()=>{
        if(shiftStartTime?.length>0 && shiftEndTime?.length>0){
            const timeCalculation = calculateTimeDifference(shiftStartTime, shiftEndTime);
            console.log(timeCalculation, "timeCalculation")
            setShiftTime(timeCalculation);
        }
    },[shiftStartTime, shiftEndTime]);

    const dispatch = useDispatch();
    const [reportTemplateId, setReportTemplateId] = useState("")

    const [newScheduleOperationTime, {loading: newScheduleTimeLoading}] = useMutation(newScheduleTimesMutation);
    
    // insert check if the employee is assigned here
    const [newScheduleCheck, {loading:newScheduleCheckLoading}] = useMutation(newScheduleCheckMutation);

    const handelSubmit = async (isPublished)=>{
        try{

            
            const scheduleInput = {
                branchId: site?.branchDetail?._id,
                customerId: site?.customerDetail?._id,
                siteGroupId: site?.sitegroupDetail?._id,
                siteId: site?._id,
                siteAreaId: area?._id,
                dutyTypeId: dutyType, 
                hourTypeId: hourType, 
                shiftStartTime: shiftStartTime,
                shiftEndTime: shiftEndTime,
                unPaidBreakDuration: unPaidBreakHour+":"+unPaidBreakMintue,
                unBillBreakDuration: unPaidBillHour+":"+unPaidBillMintue,
                bookingRef, supplierRef,
                assignedEmployee: assignedEmployee?._id, assignedAgency,
                status: assignedEmployee? assignedEmployee?._id? "2": "2" : "0",
                date: dayjs(title).format("DD/MM/YYYY"),

                firstDate: new Date(dayjs(shiftTime?.firstDate+" "+shiftStartTime, "DD/MM/YYYY HH:mm")),
                secondDate: shiftTime?.secondDate? 
                new Date(dayjs(shiftTime?.secondDate+" "+shiftEndTime, "DD/MM/YYYY HH:mm"))
                : null,
                
                firstDateHours: shiftTime?.firstDateHours,
                secondDateHours: shiftTime?.secondDateHours,

                isPublished,
                reporting: reporting,
                reportTemplateId: reporting? reportTemplateId : null

            };

            const newScheduleResponse = await newSchedule({
                variables:{
                    input: scheduleInput
                }
            });

            console.log(newScheduleResponse?.data?.newSchedule?.response?._id, newScheduleResponse, "newScheduleResponse?.newSchedule?.response?._id")
            // if employee assigned on this job
            
            if(assignedEmployee?._id && newScheduleResponse?.data?.newSchedule?.response?._id){
                const operationalTimeInput = {
                    siteId: site?._id,
                    scheduleId: newScheduleResponse?.data?.newSchedule?.response?._id,
                    operationalStartDate: new Date(dayjs(shiftTime?.firstDate+" "+shiftStartTime, "DD/MM/YYYY HH:mm")),
                    operationalStartTime:  shiftStartTime,
                    operationalEndDate: shiftTime?.secondDate? 
                    new Date(dayjs(shiftTime?.secondDate+" "+shiftEndTime, "DD/MM/YYYY HH:mm"))
                    : new Date(dayjs(shiftTime?.firstDate+" "+shiftEndTime, "DD/MM/YYYY HH:mm")),
                    operationalEndTime: shiftEndTime,
                    operationalDutyType: dutyType,
                    operationalHourType: hourType,
                    operationalAssignedEmployee: assignedEmployee?._id
                };
                await newScheduleOperationTime({
                    variables:{
                        input: operationalTimeInput
                    }
                });

                const failureIfAny = Object.keys(failureChecks)?.filter((check)=>failureChecks[check]===true)?.map((check)=> scheduleCheckFailureList[check]?.description);
                
                await newScheduleCheck({
                    variables:{
                        input:{
                            scheduleId: newScheduleResponse?.data?.newSchedule?.response?._id,
                            employeeId: assignedEmployee?._id,
                            check: failureIfAny,
                            date: dayjs(),
                        }
                    }
                });

            }


            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Job Scheduled!",
            }));
            close();
            await scheduleRefetch();
            
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }))
            close();
            await scheduleRefetch();

        }
    };

    const [searchOption, setSearchOption] = useState(false);
    const searchRef = useRef(null);
    
    
    const handleVisibleChange = (flag) => {
        setSearchOption(flag);
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setSearchOption(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [reporting, setReporting] = useState(false);

    const {data: standardTemplateData, loading: standardTemplateLoading} = useQuery(getReportTemplates,{
        variables: {
            customerId: null,
            isArchived: false
        },
        fetchPolicy: 'network-only'
    });

    const {data: customerTemplateData, loading: customerTemplateLoading} = useQuery(getReportTemplates,{
        variables: {
            customerId: site?.customerDetail?._id,
            isArchived: false
        },
        fetchPolicy: 'network-only'
    });

    const [standardTemplates, setStandardTemplates] = useState([]);
    const [customerTemplates, setCustomerTemplates] = useState([]);

    useEffect(()=>{
        if(standardTemplateData?.getReportTemplates?.response?.length){
            setStandardTemplates(standardTemplateData?.getReportTemplates?.response);
        }
        if(customerTemplateData?.getReportTemplates?.response?.length){
            setCustomerTemplates(customerTemplateData?.getReportTemplates?.response);
        }
    },[standardTemplateData?.getReportTemplates?.response, customerTemplateData?.getReportTemplates?.response]);


    const [checkFailVisible, setCheckFailVisible] = useState(false);

// set checks to render in modal
    const [failureChecks, setFailureChecks] = useState(null);


    // get employee on search to assign and 
    const [getEmployeeOnSearch, {data: empSearchData, loading: empSearchDataLoading, refetch} ]= useLazyQuery(getEmployeeForScheduleSearchQuery);

    
    const [searchResult, setSearchResult] = useState([]);
    
    useEffect(()=>{
        if(empSearchData?.getEmployeeForScheduleSearch?.response){
            setSearchResult(empSearchData?.getEmployeeForScheduleSearch?.response);
        }
    },[empSearchData?.getEmployeeForScheduleSearch?.response]);
    
    useEffect(()=>{
        if(search?.length>0){
            getEmployeeOnSearch({fetchPolicy: 'cache-and-network',
                variables: {
                    input: {
                        filters:  {advanceFilter: [[{
                            filter: "contain_exactly",
                            filterValue: [search],
                            operator: 'First name'
                        }]]}
                    }
                },
                fetchPolicy: 'cache-and-network'
            });
        }
    },[search]);


    // set width of search result
    const parentRef = useRef(null);

    const [parentWidth, setParentWidth] = useState(null);
    useEffect(() => {
        const updateParentWidth = () => {
          if (parentRef.current) {
            const width = parentRef?.current?.input?.clientWidth;
            setParentWidth(width);
          }
        };
    
        // Call the update function on initial mount and window resize
        updateParentWidth();
        window.addEventListener('resize', updateParentWidth);
    
        // Clean up the event listener on unmount
        return () => {
          window.removeEventListener('resize', updateParentWidth);
        };
    
    }, [visible, trainUnTrainedLoading, employeeDataLoading, search]);

    

    // check employee from search if it is suitable or not for specific job
    const checkEmployeeIfSuitable = (emp, accessFromOutside=false)=>{
        console.log(emp, "check if suitable for emp", emp?.blockedSites, emp?.trainedEmployeeOnSite?.find((te)=>te?.siteId==site?._id))
        const empHoliday = emp?.employeeHolidayDetail?.find((eh)=>dayjs(eh?.holidayDate).format("DD/MM/YYYY")===(dayjs(title).format("DD/MM/YYYY")))
        const empHolidayCheck = empHoliday && Object.keys(empHoliday)?.length>0? true: false;
        console.log(empHoliday, "empHoliday", empHolidayCheck)
        // if site block or not
        let blockedSite = false;
        if(emp?.blockedSites?.length>0){
            blockedSite = emp?.blockedSites?.find((bs)=>bs?.siteId==site?._id)? true: false;
        }
        // branch check
        const isBranchExist = emp?.branch?.find((b)=>b?._id==site?.branchDetail?._id);
        const branchCheck = isBranchExist? false : true;

        //============ rest period check
        const date = title.format("DD/MM/YYYY");
        const dateWithPrevRestH = dayjs(date+" "+shiftStartTime, "DD/MM/YYYY HH:mm").subtract(8, 'hour');
        // const specificArea = emp?.schedules?.find((schedule)=>schedule?.siteAreaId==area?._id);
        const specificArea = emp?.schedules[emp?.schedules?.length-1];

        
        const restPeriodCheck = specificArea && specificArea?.schedule && Object.keys(specificArea?.schedule)?.length>0 ? dayjs(specificArea?.scheduleTimes[0]?.operationalEndDate).isSame(dateWithPrevRestH) || dayjs(specificArea?.scheduleTimes[0]?.operationalEndDate).isBefore(dateWithPrevRestH) ? false : true :false
        //============ rest period check terminated
        
        //=================== duty check / experience check
        const trainingCheck= emp?.trainedEmployeeOnSite?.find((te)=>te?.siteId==site?._id)? false: true;

        //=================== duty check / experience check terminated

        //================== skill check 
        const skillCheck = allowedSkilledForSpecificArea?.length==0? false : !allowedSkilledForSpecificArea.every((areaSkill)=> emp?.employeeskills?.find((empSkill)=>empSkill?.skill==areaSkill && empSkill?.isArchive!=true));
        //================== skill check terminated
        
        //======================= visa expiry check
        const visaExpiryCheck = emp?.metadata?.requirevisa=="Yes"? 
            emp?.metadata?.visaexpirydate? 
            dayjs(emp?.metadata?.visaexpirydate, "YYYY/MM/DD").isAfter(dayjs())? false: true
            : true  
        // this check will be true if visa required and date is empty
        :false
        //======================= visa expiry check terminated

        // skill expiry check
        let skillExpiryCheck = false;
        const matchedSkill = emp?.employeeskills?.filter((empSkill)=> 
            allowedSkilledForSpecificArea?.find((skill)=>skill==empSkill?.skill) && empSkill?.isArchive!=true)?.
            filter((skill)=> skill?.fields?.find((f)=>f?.name=="expirydate"))
            .map((skill)=> {
                const isExist = skill?.fields?.find((f)=>f?.name=="expirydate");
                if(isExist){
                    return isExist.value;
                }
            });
            
        console.log("isSSS", matchedSkill)
        if(matchedSkill?.length>0){
            skillExpiryCheck = matchedSkill?.every((date)=> dayjs(date).local().isAfter(dayjs())) ? false : true
        }
            
        
        if(restPeriodCheck || skillCheck || trainingCheck || skillExpiryCheck || visaExpiryCheck || branchCheck || blockedSite || empHolidayCheck){
            setFailureChecks({restPeriodCheck, skillCheck, trainingCheck, skillExpiryCheck, visaExpiryCheck, branchCheck, empHolidayCheck, employeeData: {...emp, site:site?._id}, blockedSite});
            if(accessFromOutside){
                
                setOtherEmp((prevOtherEmp) => {
                    const isExist = prevOtherEmp.find((oemp) => oemp?._id === emp?._id);
                    return isExist
                        ? prevOtherEmp.map((oemp) => (oemp?._id === emp?._id ? emp : oemp))
                        : [...prevOtherEmp, emp];
                });
        
                setTrainedEmp((prevTrainedEmp) =>
                    prevTrainedEmp.filter((temp) => temp?._id !== emp?._id)
                );

            }else{
                setCheckFailVisible(true);
                setSearch("");
            }
            
        }else{
            if(accessFromOutside){
                
                setTrainedEmp((prevTrainedEmp) => {
                    const isExist = prevTrainedEmp.find((temp) => temp?._id === emp?._id);
                    return isExist
                        ? prevTrainedEmp.map((temp) => (temp?._id === emp?._id ? emp : temp))
                        : [...prevTrainedEmp, emp];
                });
        
                setOtherEmp((prevOtherEmp) =>
                    prevOtherEmp.filter((oemp) => oemp?._id !== emp?._id)
                );


            }else{
                setSearch("");
                setAssignedEmployee({_id:emp?._id, isSuitable: 0, isFromSearch:1,  emp})

            }
        }
        
    };

    const [bankHoliday, setBankHoliday] = useState([]);
    const getHolidays = async()=>{
      const holiday = await getUkHolidays();
      setBankHoliday(holiday?.englandWales)
    }
    const [todayBankHoliday, setTodayBankHoliday] = useState(null);
  
    useEffect(()=>{
      setTodayBankHoliday(null)
      getHolidays();
    },[]);

    useEffect(()=>{
        const isTodayBankHoliday = bankHoliday?.find((bh)=>dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(title).format("DD/MM/YYYY"))
        setTodayBankHoliday(isTodayBankHoliday);
    },[bankHoliday]);

    
    return(
        <>
            <Modal
                open={visible}
                width={employeeSlider?'60%':'40%'}
                style={{top:10, height:'100vh'}}
                footer={
                    <div className="drawer-footer" style={{padding:'26px 40px', textAlign:'left', display:'flex', columnGap:'16px', marginTop:'-25px' }}>
                        <button  
                            onClick={()=>handelSubmit(false)}
                            disabled={!employeeSlider || shiftEndTime?.length==0} 
                            className={!employeeSlider || shiftEndTime?.length==0? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                        >
                            {newScheduleLoading? <Spin indicator={<LoadingOutlined/>}/> : "Save"}
                        </button>
                        <button  
                            onClick={()=>handelSubmit(true)}
                            disabled={!employeeSlider || shiftEndTime?.length==0} 
                            className={!employeeSlider || shiftEndTime?.length==0? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                        >
                            {newScheduleLoading? <Spin indicator={<LoadingOutlined/>}/> : "Save & publish"}
                        </button>
                        <button  disabled={newScheduleLoading} className={newScheduleLoading? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={close}>
                            Cancel
                        </button>
                    </div>
                }
                closable={false}
            >
                <React.Fragment>
                    <div className='modal-header-title'>
                        <span>
                            {dayjs(title)?.format("ddd, MMM") +" "+ dayjs(title)?.format("DD")} - {site?.sitename} - {area?.areaname} 
                            <br/>
                            {todayBankHoliday?
                                <small style={{color:'white', fontSize:'14px'}}>BANK HOLIDAY - {todayBankHoliday?.title}</small>
                                :
                                null
                            }
                        </span>
                        <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                    </div>

                    <div className='modal-body'>

                        <div className="duty-modal-head">

                                <div>
                                    <span> <FontAwesomeIcon icon={faPersonBooth}/> Area</span>
                                    <div>{area?.areaname}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faStreetView}/> Site</span>
                                    <div>{site?.sitename}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faPersonDigging} /> Site Service</span>
                                    <div>{site?.metadata?.servicestype}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faLayerGroup} /> Site group</span>
                                    <div>{site?.sitegroupDetail?.sitegroupname}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faPersonBurst} /> Customer</span>
                                    <div>{site?.customerDetail?.customername}</div>
                                </div>

                                <div>
                                    <span><FontAwesomeIcon icon={faClockRotateLeft}/> Shift time</span>
                                    <div>{Object.keys(shiftTime)?.length>0? shiftTime?.totalHours : "00:00"}</div>
                                </div>

                        </div>
                        
                        <div style={{display:'flex', gap:'20px'}}>

                            <div className="dutyForm">
                                <table cellSpacing={10}>
                                    <tr>
                                        <td> <label>Duty Type</label></td>
                                        <td>
                                            <Select
                                                showSearch
                                                optionFilterProp="children"
                                                style={{width:'-webkit-fill-available'}}
                                                loading={dutyTypeLoading}
                                                disabled={dutyTypeLoading}
                                                className="custom-select"
                                                suffixIcon={<div className="dropdowncaret"></div>}
                                                placeholder="Select duty type  "
                                                onChange={(e)=>setDutyType(e)}
                                            >
                                                {dutyTypeData?.getPayLevel?.response?.map((dutyTypeData)=>(
                                                    <Option value={dutyTypeData?._id}>{dutyTypeData?.name}</Option>
                                                ))}
                                            </Select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>Hour Type</label></td>
                                        <td>
                                            <Select
                                                showSearch
                                                style={{width:'-webkit-fill-available'}}
                                                optionFilterProp="children"
                                                loading={hourTypeDataLoading}
                                                disabled={hourTypeDataLoading}
                                                className='custom-select'  
                                                suffixIcon={<div className='dropdowncaret'></div>}
                                                placeholder="Select hour type"
                                                onChange={(e)=>setHourType(e)}
                                            >
                                                {hourTypeData?.getShiftType?.response?.map((hour)=>(
                                                    <Option value={hour?._id}>{hour?.name}</Option>
                                                ))}
                                            </Select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <label>Shift Start Time</label>
                                        </td>

                                        <td>
                                            <Form.Item>
                                                <input 
                                                    type={"time"} 
                                                    className="generic-input-control"
                                                    style={{height:'20px'}}
                                                    placeholder="Shift end time in 24 hours"
                                                    onChange={(e)=>setShiftStartTime(e.target.value)}
                                                />
                                            </Form.Item>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>Shift End Time</label></td>
                                        <td>
                                            
                                            <Form.Item>
                                                <input 
                                                    type={"time"} 
                                                    className="generic-input-control"
                                                    style={{height:'20px'}}
                                                    placeholder="Shift end time in 24 hours"
                                                    onChange={(e)=>setShiftEndTime(e.target.value)}
                                                />
                                            </Form.Item>

                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>UnPaid Break Duration</label></td>
                                        <td>
                                            <div style={{display:'flex', gap:'16px'}}>
                                                <Form.Item  className="numberInput" style={{margin:'0'}}>
                                                    <InputNumber placeholder="HH" 
                                                        min={0}
                                                        max={3}
                                                        onChange={(e)=>setUnPaidBreakHour(e)}
                                                        className='generic-input-control'                         
                                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                                        downHandler={<FontAwesomeIcon  style={0 > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                                    />
                                                </Form.Item>
                                                <Form.Item  className="numberInput" style={{margin:'0'}} >
                                                    <InputNumber placeholder="MM"
                                                        min={0}
                                                        max={59}
                                                        className='generic-input-control'   
                                                        onChange={(e)=>setUnPaidBreakMintue(e)}                      
                                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                                        downHandler={<FontAwesomeIcon  style={0 > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>UnBill Break Duration</label></td>
                                        <td>
                                            <div style={{display:'flex', gap:'16px'}}>
                                                <Form.Item  className="numberInput" style={{margin:'0'}}>
                                                    <InputNumber placeholder="HH" 
                                                        min={0}
                                                        max={3}
                                                        onChange={(e)=>setUnPaidBillHour(e)}
                                                        className='generic-input-control'                         
                                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                                        downHandler={<FontAwesomeIcon  style={0 > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                                    />
                                                </Form.Item>
                                                <Form.Item  className="numberInput" style={{margin:'0'}} >
                                                    <InputNumber placeholder="MM"
                                                        min={0}
                                                        max={59}
                                                        onChange={(e)=>setUnPaidBillMintue(e)}
                                                        className='generic-input-control'                         
                                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                                        downHandler={<FontAwesomeIcon  style={0 > 1 && {color:'#0091ae'}} icon={faChevronDown} />}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>Booking Ref</label></td>
                                        <td>
                                            <Input
                                                onChange={(e)=>setBookingRef(e.target.value)}
                                                value={bookingRef}
                                                className='generic-input-control'  
                                                suffix={<Popover content="Click to generate booking ref"><FontAwesomeIcon icon={faDedent} style={{cursor:'pointer'}}/></Popover>}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><label>Supplier Ref</label></td>
                                        <td>
                                            <Input
                                                onChange={(e)=>setSupplierRef(e.target.value)}
                                                value={supplierRef}
                                                className='generic-input-control'                                      
                                                suffix={<Popover content="Click to generate supplier ref"><FontAwesomeIcon icon={faDedent} style={{cursor:'pointer'}}/></Popover>}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><label>Reporting</label></td>
                                        <td>
                                            <div style={{display:'flex', gap:'16px', alignItems:'center', height: '45px'}}>
                                                
                                                <Switch 
                                                    checkedChildren={reporting?<span>ON</span>:null}
                                                    unCheckedChildren={reporting==false?<span>OFF</span>:null}
                                                    onChange={(e)=>setReporting(e)}
                                                    
                                                />

                                                {reporting? 

                                                    <Select
                                                        style={{width:'-webkit-fill-available'}}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            (option?.label?.props?.children ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        className='custom-select'  
                                                        suffixIcon={<div className='dropdowncaret'></div>}
                                                        onChange={(e)=>setReportTemplateId(e)}
                                                        placeholder={"Select report template"}
                                                        options={[
                                                            {
                                                                label: <span style={{color:'rgba(0, 0, 0, 0.45)'}}>Custom Templates</span>,
                                                                title: 'Custom template',
                                                                options: customerTemplates?.map((templates)=>({label: templates?.templatename, value: templates?._id}))
                                                            }, 
                                                            {
                                                                label: <span style={{color:'rgba(0, 0, 0, 0.45)'}}>Standard Templates</span>,
                                                                title: 'Standard template',
                                                                options: standardTemplates?.map((templates)=>({label: templates?.templatename, value: templates?._id}))
                                                            }
                                                        ]}
                                                    />

                                                : null}

                                            </div>
                                        </td>
                                    </tr>

                                </table>
                                
                                
                            </div>
                            
                            {employeeSlider &&
                                <div className={employeeSlider?"emplist":""}>
                                
                                    <div ref={searchRef} style={{display:'flex',alignItems:'center', gap:'5px'}}>
                                        <div style={{width:'100%'}}>
                                            <Input
                                                className="generic-input-control"
                                                placeholder="Type to search employee"
                                                style={{width:'100%', marginBottom:'16px'}}
                                                suffixIcon={<div className="dropdowncaret"></div>}
                                                onChange={(e)=>handelSearch(e.target.value)}
                                                ref={parentRef}
                                            />
                                            {
                                                assignedEmployee?.isFromSearch && assignedEmployee?.isFromSearch==1?
                                                <Tag closable onClose={()=>setAssignedEmployee()} style={{marginBottom:'16px', display:'flex', alignItems:'center', columnGap:'10px', width:'fit-content'}}>
                                                    <div className="employeeProfile">
                                                        <Avatar  size={20} style={{background:'lightgrey'}} src={assignedEmployee?.emp?.metadata?.imageURL || <UserOutlined/>} />
                                                    </div>
                                                    {assignedEmployee?.emp?.firstname} {assignedEmployee?.emp?.lastname || ""}
                                                </Tag>
                                                :null
                                            }
                                            {search?.length>0?
                                                <div className="moveGroupData scheduleSearchEmployee" style={{width: parentWidth}}>
                                                    {
                                                    employeeDataLoading?
                                                        <div style={{margin:'auto', display:'table', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                           <div className="popoverdataitem" style={{textAlign:'center'}}>
                                                                <Spinner/>
                                                           </div>
                                                        </div>
                                                    :
                                                    
                                                    searchResult?.length==0?
                                                    <div style={{margin:'auto', display:'table', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                           <div className="popoverdataitem" style={{textAlign:'center'}}>
                                                                No employee found
                                                           </div>
                                                    </div>
                                                    :
                                                    searchResult?.map((result)=>{
                                                        const lastname = result?.lastname? result?.lastname : "";
                                                        const firstname = result?.firstname? result?.firstname : "";
                                                        return(
                                                            <div className="popoverdataitem" onClick={()=>checkEmployeeIfSuitable(result)}>
                                                                <div style={{display:'flex', columnGap:'12px'}}>
                                                                    <div className="employeeProfile">
                                                                        <Avatar  size={40} style={{background:'lightgrey'}} src={result?.metadata?.imageURL || <UserOutlined/>} />
                                                                    </div>
                                                                    <div>
                                                                        <div>{firstname +" "+ lastname}</div>
                                                                        <div style={{color:'grey', fontWeight:300}}>{result?.metadata?.employmenttype} {result?.metadata?.agency[0]?.agencyname?  "["+ result?.metadata?.agency[0]?.agencyname +"]" : null }</div>
                                                                    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    )
                                                    }

                                                </div>
                                                :null
                                            }
                                        </div>

                                        <Dropdown ref={searchRef} 
                                            onVisibleChange={handleVisibleChange}
                                            overlay={
                                                <Menu style={{width: '200px', borderRadius:0}}>
                                                    <Menu.Item key="1">
                                                    <Checkbox  onChange={(e)=>("all")}>All Employees</Checkbox> 
                                                    </Menu.Item>    
                                                    <Menu.Item key="2">
                                                    <Checkbox  onChange={(e)=>("agency")}>Agency Employees</Checkbox> 
                                                    </Menu.Item>
                                                </Menu>
                                            } visible={searchOption} placement="bottomLeft"
            
                                        >
                                            <FontAwesomeIcon 
                                                style={{fontSize:'24px', color:'#0091ae', cursor:'pointer', marginBottom:'15px', display:'none'}} 
                                                icon={faEllipsisVertical} onClick={()=>{setSearchOption(!searchOption)}}
                                                
                                            /> 
                                        </Dropdown>

                                    </div>

                                    {trainUnTrainedLoading? 
                                        <div style={{display:'table', margin:'auto', textAlign:'center', width:'100%'}}>
                                            <Spinner/>
                                        </div>
                                        :
                                        true?
                                        <>

                                            <div style={{overflow:'auto', height:'185px'}}>
                                                <div style={{background:'#e5f5f8', letterSpacing:'0.4', padding:'5px', fontWeight:'400'}}>Suitable Employees</div>
                                                <div style={{marginBottom:'16px'}}></div>
                                                {trainedEmp?.map((emp)=>{
                                                    return(
                                                        <div className={assignedEmployee?._id==emp?._id?"suitable-emp activeTab" : "suitable-emp"} >
                                                            <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                                                 
                                                                <div style={{display:'flex', gap:'15px'}} onClick={()=>setAssignedEmployee({_id:emp?._id, isSuitable: 1})}>
                                                                    {
                                                                        emp?.metadata?.imageURL?
                                                                        <Avatar size={40} src={emp?.metadata?.imageURL} />
                                                                        :
                                                                        <Avatar size={40} src={<UserOutlined/>} style={{background:'rgba(0, 0, 0, 0.25)'}}/>
                                                                    }
                                                                    <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
                                                                        <span style={{fontSize:'13px'}}>{emp?.firstname +" "+(emp?.lastname || "")}</span>
                                                                        <span style={{fontSize:'10px'}}>{emp?.metadata?.employmenttype? emp?.metadata?.employmenttype: '--'}</span> 
                                                                    </div>
                                                                </div>

                                                                {assignedEmployee?._id==emp?._id?
                                                                    <FontAwesomeIcon icon={faTimes} className='close'  style={{paddingRight:'16px'}}  onClick={()=>setAssignedEmployee()} />
                                                                    :   
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                            <div style={{overflow:'auto', height:'185px', marginTop:'16px'}}>
                                                <div style={{background:'#e5f5f8', letterSpacing:'0.4', padding:'5px', fontWeight:'400'}}>Other Employees On Site <small style={{color:'red'}}> (May cause checks violation) </small></div>
                                                <div style={{marginBottom:'16px'}}></div>
                                                {otherEmp?.map((emp)=>{

                                                    return(
                                                        <div className={assignedEmployee?._id==emp?._id?"suitable-emp activeTab" : "suitable-emp"}  >
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                                                    <div style={{display:'flex', gap:'15px'}} onClick={()=>{checkEmployeeIfSuitable(emp);}} >
                                                                        <div className="employeeProfile">
                                                                            {
                                                                                emp?.metadata?.imageURL?
                                                                                <Avatar size={40} src={emp?.metadata?.imageURL} />
                                        
                                                                                :
                                                                                <div className="employeeProfile">
                                                                                    <Avatar size={40} src={<UserOutlined/>} style={{background:'lightgrey'}}/>
                                                                                </div>

                                                                            }
                                                                        </div>
                                                                        <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
                                                                            <span style={{fontSize:'13px'}}>{emp?.firstname +" "+(emp?.lastname||"")}</span>
                                                                            <span style={{fontSize:'10px'}}>{emp?.metadata?.employmenttype? emp?.metadata?.employmenttype: '--'}</span> 
                                                                        </div>
                                                                    
                                                                    </div>
                                                                    {assignedEmployee?._id==emp?._id?
                                                                        <FontAwesomeIcon icon={faTimes}  style={{paddingRight:'16px'}} onClick={()=>setAssignedEmployee()} />
                                                                        :   
                                                                        null
                                                                    }
                                                                </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                        </> 
                                        :
                                        null
                                }
                                    
                                </div>
                            }

                        </div>

                    </div>
                                        
                </React.Fragment>
            </Modal>
            <CheckFailureModal visible={checkFailVisible} save={setAssignedEmployee} failureChecks={failureChecks} close={()=>setCheckFailVisible(!checkFailVisible)} />
        </>
    )
}

