import { useState } from "react";
import { TaskFormDrawer } from "./component/task.formDrawer";
import { TableGrid } from "@src/components/tablegrid";
import { GridFilter } from "@src/components/tablegrid/gridFilter/gridFilter";
import DraggableTab from "@src/components/dragableTab";
import { GridHeader } from "@src/components/tablegrid/header";
import { useMutation, useQuery } from "@apollo/client";
import { getAllTaskQuery, getTaskViewQuery, newTaskViewMutation, updateTaskViewMutation } from "../../util/mutation/task.mutation";
import { TaskGridFilter } from "./component/taskGridFilter";
import { useSelector } from "react-redux";
import { Popover, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { TaskDataTable } from "./component/tablegrid";
import dayjs from "dayjs";



export const TaskPage = ()=>{
    const [visible, setVisible] = useState(false);
    
    const {data: taskViewData, loading: taskViewDataLoading, refetch: taskViewRefetch} = useQuery(getTaskViewQuery)
    
    const [updateView, {loading: updateViewLoading}] = useMutation(updateTaskViewMutation);
    const [createTaskView, {loading: createTaskViewLoading}] = useMutation(newTaskViewMutation);
    const [object, setObject] = useState([
      
      {
        isReadOnly: true,
        propertyDetail:{ label: 'Title', isReadOnly: true},
      },
      
      {
        isReadOnly: true,
        propertyDetail:{ label: 'Type', isReadOnly: true},
      },
      
      {
        isReadOnly: true,
        propertyDetail:{ label: 'Priority', isReadOnly: true},
      },
      
      {
        isReadOnly: true,
        propertyDetail:{ label: 'Status', isReadOnly: true},
      },

      {
        isReadOnly: true,
        propertyDetail:{ label: 'Associated Employee', isReadOnly: true},
      },
      
      {
        isReadOnly: true,
        propertyDetail:{ label: 'Associated Site', isReadOnly: true},
      },
      
      
      
      {
        isReadOnly: true,
        propertyDetail:{ label: 'Assigned To', isReadOnly: true},
      },
    ]);
    
    // here the quick and advance filters that we reterive from the selected view
    const {quickFilter, advanceFilter} = useSelector(state=>state.quickFilterReducer);

    const {data: taskData, loading: taskDataLoading, refetch: taskRefetch} = useQuery(getAllTaskQuery,{fetchPolicy: 'cache-and-network',
      variables: {
          input: {
              filters: quickFilter && Object.values(quickFilter)?.length>0 ? {quickFilter} : null
          }
      }
    });

    const [dynamicColumn, setDynamicColumn] = useState([]);

    return(

        
        <div className="tablegrid">
        <GridHeader 
          title={"Task"}
          to={"/employee/editform"}
          from={"/user/employee"}
          record={taskData?.getAllTask?.response?.length} 
          createAction={()=>setVisible(!visible)} 
        />
      
        <DraggableTab             
          viewList = {taskViewData?.getTaskView?.response}
          loading = {taskViewDataLoading}
          refetch = {taskViewRefetch}
          updateView = {updateView}
          createView={createTaskView}
          createViewLoading={createTaskViewLoading}
        />

        <TaskGridFilter
            // openAdvanceFilter={()=>setFilterModal(true)}
            updateView={updateView}
            viewList = {taskViewData?.getTaskView?.response}
            refetch= {async()=>{
              await taskViewRefetch();
            }}
        />

        
        <TaskDataTable
            title={"Task"}
            data={taskData?.getAllTask?.response?.map((task)=>{
              const isOverDue =  dayjs(task?.dueDate)<dayjs() &&  dayjs(dayjs().format('hh:mm A'), 'hh:mm A').isAfter(dayjs(task?.dueTime, 'hh:mm A'));    

              return({
                ...task,
                status: 
                  task?.status!=="DONE" && isOverDue? 
                    // <Popover
                    //   content={"Task overdue"}
                    // >
                    //   <div className="status-circle status-overdue">
                    //     <FontAwesomeIcon icon={faCheck}/>
                    //   </div> 
                    // </Popover>
                    "Overdued"
                  :
                  
                  task?.status=="DONE"? 
                    // <Popover
                    //   content={"Task has been completed"}
                    // >
                    //   <div className="status-circle status-done">
                    //     <FontAwesomeIcon icon={faCheck}/>
                    //   </div>
                    // </Popover>
                    "Completed"

                  :
                  "Pending"
                  // <Popover
                  //   content={"Task is Pending"}
                  // >
                  //   <div className="status-circle">
                  //     <FontAwesomeIcon icon={faCheck}/>
                  //   </div>
                  // </Popover>
  
                ,
                key: task?._id,
                associatedemployee: task?.associatedemployee?.length>0 ? task?.associatedemployee?.map((emp)=>(<Tag>{emp?.firstname+" "+emp?.lastname}</Tag>)) : "--" ,
                associatedsite: task?.associatedsite?.length>0? task?.associatedsite?.map((site)=>(<Tag>{site?.sitename}</Tag>)) : "--",
                assignedto: task?.assignedtodetail? task?.assignedtodetail?.firstname+" "+task?.assignedtodetail?.lastname: "--",
                link : task?.associatedemployee?.length>0? "employee-detail/"+task?.associatedemployee[0]?._id+"/tab/task" : task?.associatedsite?.length>0? "site-detail/"+task?.associatedsite[0]?._id+"/tab/task" : null
              })
            })}

            refetch={taskRefetch}
            setDynamicColumn={setDynamicColumn}
            dynamicColumn={dynamicColumn}
            viewRefetch={taskViewRefetch}
            view={taskViewData?.getTaskView?.response?.find((e)=>e._id==sessionStorage.getItem("selectedViewId"))?.viewFields || []}
            loading={taskDataLoading || taskViewDataLoading}
            // loading={false}
            objectData={object}
            header={true}
            noHeader={false}

        />

        {visible && <TaskFormDrawer refetchTask={taskRefetch} visible={visible} onClose={()=>setVisible(false)}/>}
      </div>
    );
}