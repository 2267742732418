import { gql } from "@apollo/client";

export const getScheduleCheckCallsByDateQuery = gql`
query($date: String!){
  getScheduleCheckCallsByDate(date: $date) {
    message
    response
  }
}
`;
