import { useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/build/pdf.worker.entry';

export const PdfThumbnail = ({ file }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const renderPdfThumbnail = async () => {
            const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
            const page = await pdf.getPage(1);
            const scale = 0.5;
            const viewport = page.getViewport({ scale });

            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            await page.render(renderContext).promise;
        };

        renderPdfThumbnail();
    }, [file]);

    return <canvas ref={canvasRef}></canvas>;
};
