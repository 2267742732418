import { GridHeader } from "@src/components/tablegrid/header";
import { TableGrid } from "@src/components/tablegrid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { CustomerObjectQuery } from "@src/util/query/customer.query";
import { AddCustomerMutation, UpdateBulkCustomerMutation } from "../../util/mutation/customer.mutation";
import { getCustomerQuery } from "@src/util/query/customer.query";
import { CustomerFormDrawer } from "./customerFormDrawer";
import { EditColumn } from "@src/components/table/editColumn/editColumn.modal";
import { GetPropertyByGroupQuery } from '@src/util/query/properties.query';
import { getCustomerViewQuery } from "@src/util/query/customerView.query";
import { newCustomerViewMutation, updateCustomerViewMutation } from "@src/util/mutation/customerView.mutation";
import DraggableTab from "@src/components/dragableTab";
import { GridFilter } from "@src/components/tablegrid/gridFilter/gridFilter";
import { AdvanceFilter } from "@src/components/advanceFilter/advanceFilter";
import { objectType } from "@src/util/types/object.types";
import { setEditGridColumn } from "@src/middleware/redux/reducers/properties.reducer";


export const CustomerPage = ()=>{
    
    const {editGridColumn} = useSelector(state => state.propertyReducer);
    const {data:customerObject, loading: customerObjectLoading, refetch: customerObjectRefetch} = useQuery(CustomerObjectQuery);
    // customerObject?.getCustomerObject?.response
    
    const [customerSchema, setCustomerSchema] = useState();

    useEffect(()=>{
        if(customerObject){
            setCustomerSchema(customerObject?.getCustomerObject?.response);
        }
    },[customerObject]);

     // states that we had to define for formDrawer
    const [data, setData] = useState([]);
    const [isBtnEnable, setBtn] = useState(true);
    const [isoverlay, setIsOverlay] = useState(true);
    const [customerModal, setCustomerModal] = useState(false);

    const dispatch = useDispatch();

    // Add new Employee while form creation
    const [addCustomerMutation, {loading: processloading}] = useMutation(AddCustomerMutation);
    const {quickFilter, advanceFilter} = useSelector(state=>state.quickFilterReducer);


    const {data: customerData, loading: customerLoading, refetch} = useQuery(getCustomerQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: quickFilter && Object.values(quickFilter)?.length>0 && advanceFilter?.length>0 ? 
                {quickFilter, advanceFilter: [...advanceFilter]} :
                quickFilter && Object.values(quickFilter)?.length>0 ? {quickFilter} : 
                advanceFilter?.length>0 ? {advanceFilter: [...advanceFilter]} : null
            }
        }
    });

    const customerMutation = async (customer) =>{
        try{
            await addCustomerMutation({variables: {input: customer}});
            await customerObjectRefetch();
            await refetch();

            dispatch(setNotification({
                notificationState:true, 
                message: "Customer was added successfully",
                error: false,
            }));
            setData([]);
            setBtn(true);
            setIsOverlay(true);

        }catch(err){
            dispatch(setNotification({
                notificationState: true,
                error: true,
                message: err?.message
            }));
        }
    }

    const handelSubmit = async (isCloseAble)=>{
        const customername = data?.find((d)=>(Object.keys(d)[0]=="customername"));
       
        let metadata = {};
        data?.map(d=>{
          if(Object.keys(d)[0]!=="customername"){
            metadata[Object.keys(d)[0]]= Object.values(d)[0]
          }
        });
        const customerData = {
          ...customername,
          metadata,
        }
        // handel mutation
        await customerMutation(customerData);

        if(isCloseAble){
            setCustomerModal(!customerModal);
        }

    };

    // dynamic column state for table gird
    const [dynamicColumn, setDynamicColumn] = useState([]);
    const [updateBulkCustomer, {loading: updateBulkCustomerLoading}] = useMutation(UpdateBulkCustomerMutation);

    const handelBulkUpdateSave = async (property, record)=>{
        try{
            let schemaFields = {};
            
            
              if(property?.field==="customername"){
                schemaFields[property?.field] = property?.value;
              }
              else{
                schemaFields['metadata.'+property.field]=property?.value;
              }
            
            await updateBulkCustomer({
                variables:{
                    input:{
                        _ids: [...record],
                        properties: {...schemaFields},
                    }
                }
            });
  
            dispatch(setNotification({
                message: "Customer Updated Successfully",
                notificationState: true,
                error: false
            }));
            await refetch();
            return true;
        }
        catch(err){            
            dispatch(setNotification({
                message: "An error encountered while updating Customer"+err.message,
                notificationState: true,
                error: true
            }));
            return false;
        }
    };

    const {data:customerViewData, loading: customerViewLoading, refetch: customerViewRefetch} = useQuery(getCustomerViewQuery, {
        fetchPolicy: 'network-only'
    });
    
    const [newCustomerView, {loading: newCustomerLoading}] = useMutation(newCustomerViewMutation);
    const [updateCustomerView, {loading: updateCustomerLoading}] = useMutation(updateCustomerViewMutation);
    const [filterModal, setFilterModal] = useState(false);

    
    const {data: CustomerGroupProperty} = useQuery(GetPropertyByGroupQuery,{
      variables:{
        objectType: "Customer"
      },
      fetchPolicy:'network-only'
    });

    useEffect(()=>{
        customerViewRefetch();
    }, [editGridColumn]);


    return(
        <React.Fragment>
            
        <div className="tablegrid">
          <GridHeader 
            title={"Customers"} 
            to={"/branch/editform"}
            record={customerData?.customers?.length} 
            from={"/user/branch"}      
            createAction={()=>{setCustomerModal(true);}} 
          />

          {/* <div className="hr" style={{margin:'40px 50px', width:'auto'}}></div> */}
        
          <DraggableTab  
              viewList = {customerViewData?.customerView?.response}
              loading = {customerViewLoading}
              refetch = {customerViewRefetch}
              updateView = {updateCustomerView}
              createView={newCustomerView}
              createViewLoading={newCustomerLoading}
          />

          <GridFilter
              openAdvanceFilter={()=>setFilterModal(true)}
              updateView={updateCustomerView}
              refetch={async ()=>{ await customerViewRefetch(); await refetch(); }}
              viewList = {customerViewData?.customerView?.response}
          />

          <AdvanceFilter 
            visible = {filterModal} 
            onClose = {()=>setFilterModal(false)}
            objectData = {customerSchema}
            object={"Customer"}
            groupProperty = {CustomerGroupProperty?.getPropertyByGroup?.data || []}
          />
          
            <TableGrid
                title={"Customer"}
                data={customerData?.customers?.map((customer)=>({...customer, key:customer?._id})) || customerData?.customers}
                refetch={refetch}
                setDynamicColumn={setDynamicColumn}
                dynamicColumn={dynamicColumn}
                viewRefetch={customerViewRefetch}
                view={customerViewData?.customerView?.response?.find((e)=>e._id==sessionStorage.getItem("selectedViewId"))?.viewFields || []}
                loading={customerLoading || customerViewLoading || customerObjectLoading}
                objectData={customerSchema}
                detailpage={"customer-detail/"}
                handelBulkUpdateSave={handelBulkUpdateSave}
                noHeader={false}
            />
        
        </div>

        <CustomerFormDrawer
            objectData={customerSchema}
            objectLoading={customerObjectLoading}
            handelSubmit={handelSubmit}
            visible={false || customerModal} 
            refetch={refetch} 
            setBtn={setBtn}
            data={data}
            setData={setData}
            isBtnEnable={isBtnEnable}
            isoverlay={isoverlay}
            setIsOverlay={setIsOverlay}
            loading={processloading}
            onClose={()=>setCustomerModal(!customerModal)} 
            to={""}
            from={""}
            title={"Customer"}
        />
        
        {editGridColumn?
            <EditColumn 
              objectType={objectType.Customer} 
              visible={editGridColumn} 
              onClose={()=>dispatch(setEditGridColumn(false))}
              properties = {customerSchema}
              propertiesRefetch = {customerObjectRefetch}
              loading = {customerObjectLoading || customerViewLoading}
              disable = {updateCustomerLoading}
              refetchView = {async ()=>{
                await customerViewRefetch();
                await customerObjectRefetch();
              }}
              view = {customerViewData?.customerView?.response?.find((e)=>e._id==sessionStorage.getItem("selectedViewId"))?.viewFields || []}
              updateRenderedView = {updateCustomerView}
            />
          : null
        }
       
        </React.Fragment>
    );
}