import { DocumentThumbnail } from "./document.thumbnail";
import { ImageThumbnail } from "./image.thumbnail";
import { PdfThumbnail } from "./pdf.thumbnail";
import { VideoThumbnail } from "./video.thumbnail";

export const FileThumbnail = ({ file, extension }) => {
    

    if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(extension)) {
        return <ImageThumbnail file={file} />;
    }

    if (extension === 'pdf') {
        return <PdfThumbnail file={file} />;
    }

    if (['mp4', 'avi', 'mov', 'wmv', 'flv'].includes(extension)) {
        return <VideoThumbnail file={file} />;
    }

    if (['docx', 'xlsx'].includes(extension)) {
        return <DocumentThumbnail file={file} />;
    }

    return <div>Unsupported file type</div>;
};
