import { GridHeader } from "@src/components/tablegrid/header";
import { TableGrid } from "@src/components/tablegrid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { objectType } from "@src/util/types/object.types";
import { useDispatch } from "react-redux";
import { GROUPLIST } from "@src/util/query/group.query"
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { SiteFormDrawer } from "./siteFormDrawer";
import { SiteObjectQuery, getSiteQuery, getSitesQuery } from "@src/util/query/site.query";
import { AddSiteMutation, UpdateBulkSiteMutation } from "@src/util/mutation/site.mutation";
import DraggableTab from "@src/components/dragableTab";
import { GridFilter } from "@src/components/tablegrid/gridFilter/gridFilter";
import { AdvanceFilter } from "@src/components/advanceFilter/advanceFilter";
import { EditColumn } from "@src/components/table/editColumn/editColumn.modal";
import { setEditGridColumn } from "@src/middleware/redux/reducers/properties.reducer";
import { AllSiteViewQuery } from "@src/util/query/siteView.query";
import { newSiteViewMutation, updateSiteViewMutation } from "@src/util/mutation/siteViewMutation";
import Spinner from "@src/components/spinner";
import { resetAllFilter, resetAdvanceFilter } from "@src/middleware/redux/reducers/quickFilter";

export const SitePage = ()=>{
    
    const {editGridColumn} = useSelector(state => state.propertyReducer);
    const {data:siteObject, loading: siteObjectLoading, refetch: siteObjectRefetch} = useQuery(SiteObjectQuery);

    const [siteSchema, setSiteSchema] = useState();

    useEffect(()=>{
        if(siteObject?.getSiteObject?.response){
          setSiteSchema(siteObject?.getSiteObject?.response);
        }
    },[siteObject?.getSiteObject?.response]);

     // states that we had to define for formDrawer
    const [data, setData] = useState([]);
    const [isBtnEnable, setBtn] = useState(true);
    const [isoverlay, setIsOverlay] = useState(true);
    const [siteGroupModal, setSiteGroupModal] = useState(false);

    const dispatch = useDispatch();

    // Add new Employee while form creation
    const [addSiteMutation, {loading: processloading}] = useMutation(AddSiteMutation);
    
    // here the quick and advance filters that we reterive from the selected view
    const {quickFilter, advanceFilter} = useSelector(state=>state.quickFilterReducer);

    const {data: siteData, loading: siteLoading, refetch} = useQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
              filters: quickFilter && Object.values(quickFilter)?.length>0 && advanceFilter?.length>0 ? 
              {quickFilter, advanceFilter: [...advanceFilter]} :
              quickFilter && Object.values(quickFilter)?.length>0 ? {quickFilter} : 
              advanceFilter?.length>0 ? {advanceFilter: [...advanceFilter]} : null
          }
        }
    });


    const siteGroupMutation = async (siteGroup) =>{
        try{
            await addSiteMutation({variables: {input: siteGroup}});
            await siteObjectRefetch();
            await refetch();

            dispatch(setNotification({
                notificationState:true, 
                message: "Site  was added successfully",
                error: false,
            }));
            setData([]);
            setBtn(true);
            setIsOverlay(true);

        }catch(err){
            dispatch(setNotification({
                notificationState: true,
                error: true,
                message: err?.message
            }));
        }
    }

    const handelSubmit = async (isCloseAble)=>{
        const sitename = data?.find((d)=>(Object.keys(d)[0]=="sitename"));
        const postcode = data?.find((d)=>(Object.keys(d)[0]=="postcode"));
        const {sitegroup} = data?.find((d)=>(Object.keys(d)[0]=="sitegroup"));
        const contractstartdate = data?.find((d)=>(Object.keys(d)[0]=="contractstartdate"));
       
        let metadata = {};
        data?.map(d=>{
          if(Object.keys(d)[0]!=="sitename" && Object.keys(d)[0]!=="postcode" && Object.keys(d)[0]!=="sitegroup"  && Object.keys(d)[0]!=="contractstartdate" ){
            metadata[Object.keys(d)[0]]= Object.values(d)[0]
          }
        });
        const siteData = {
          ...sitename,
          ...postcode,
          ...contractstartdate,
          sitegroupId: sitegroup,
          metadata,
        }
        // handel mutation
        await siteGroupMutation(siteData);

        if(isCloseAble){
            setSiteGroupModal(!siteGroupModal);
        }

    };

    // dynamic column state for table gird
    const [dynamicColumn, setDynamicColumn] = useState([]);
    const [updateBulkSite, {loading: updateBulkSiteGroupLoading}] = useMutation(UpdateBulkSiteMutation);

    const handelBulkUpdateSave = async (property, record)=>{
        try{
            let schemaFields = {};
            
            
              if(property?.field==="sitename" || property?.field==="postcode" || property?.field==="sitegroupId" || property?.field==="contractstartdate" ){
                schemaFields[property?.field] = property?.value;
              }
              else{
                schemaFields['metadata.'+property.field]=property?.value;
              }
            
            await updateBulkSite({
                variables:{
                    input:{
                        _ids: [...record],
                        properties: {...schemaFields},
                    }
                }
            });
  
            dispatch(setNotification({
                message: "Site groups Updated Successfully",
                notificationState: true,
                error: false
            }));
            await refetch();
            return true;
        }
        catch(err){            
            dispatch(setNotification({
                message: "An error encountered while updating site group"+err.message,
                notificationState: true,
                error: true
            }));
            return false;
        }
    };

    
    
    const {data:siteGrpFields, loading, refetch:refetchAllSiteFields } = useQuery(GROUPLIST,{
      variables: {
          objectType: objectType?.Site
      }
    });

    const [allSiteProp, setAllSiteProp] = useState([]);

    useEffect(()=>{
      if(siteGrpFields?.groupList){
        let propList = [];
        siteGrpFields?.groupList?.map((grpList)=> grpList?.propertyList?.map((prop)=> propList.push(prop)));
        
        setAllSiteProp([...propList]);
      }
    },[siteGrpFields]);

    const [advanceFilterModal, setAdvanceFilerModal] = useState(false);
    const {data: allSiteViewData, loading: allSiteViewLoading, refetch: allSiteViewRefetch} = useQuery(AllSiteViewQuery,{
      fetchPolicy: 'network-only'
    });
    
    const [newSiteView, {loading: newSiteViewLoading}] = useMutation(newSiteViewMutation);
    const [updateSiteView, {loading: updateSiteViewLoading}] = useMutation(updateSiteViewMutation);

    // reset filters on component unmount
    useEffect(()=>{
      return ()=>{
        dispatch(resetAdvanceFilter());
        dispatch(resetAllFilter());
      }
    },[]);


    if(allSiteViewLoading || siteObjectLoading){
      return(
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height:'100vh'}}>
            <Spinner color={'#ff7a53'} fontSize={80}/>
        </div>
      )
    }else{
      return(
          <React.Fragment>
              
          <div className="tablegrid">
            <GridHeader 
              title={"Site"} 
              to={"/branch/editform"}
              record={siteData?.sites?.length} 
              from={"/user/branch"}      
              createAction={()=>{setSiteGroupModal(true);}} 
            />
  
            {/* <div className="hr" style={{margin:'40px 50px', width:'auto'}}></div> */}
          
            <DraggableTab  
              viewList = {allSiteViewData?.getsiteViews?.response}
              loading = {allSiteViewLoading}
              refetch = {allSiteViewRefetch}
              updateView = {updateSiteView}
              createView = {newSiteView}
              createViewLoading = {newSiteViewLoading}
            />
  
            <GridFilter
              openAdvanceFilter={()=>setAdvanceFilerModal(true)}
              updateView={updateSiteView}
              refetch={async ()=>{
                await allSiteViewRefetch();
                await siteObjectRefetch();
              }}
              viewList = {allSiteViewData?.getsiteViews?.response}
  
            />
  
            <AdvanceFilter 
              visible = {advanceFilterModal} 
              onClose = {()=>setAdvanceFilerModal(false)}
              objectData = {siteSchema}
              object={"Site"}
              groupProperty = {siteGrpFields?.groupList?.map((grpList)=> ({...grpList, properties: grpList?.propertyList, _id: grpList?.name}))}
            />
            
              <TableGrid
                  title={"Site"}
                  data={siteData?.sites?.map((sitegroup)=>({...sitegroup, key:sitegroup?._id, sitegroup: sitegroup?.sitegroupDetail?.sitegroupname  }))}
                  refetch={async ()=>{
                    await allSiteViewRefetch();
                    await siteObjectRefetch();
                  }}
                  setDynamicColumn={setDynamicColumn}
                  dynamicColumn={dynamicColumn}
                  viewRefetch={()=>{return false;}}
                  view={allSiteViewData?.getsiteViews?.response?.find((e)=>e._id==sessionStorage.getItem("selectedViewId"))?.viewFields || []}
                  loading={allSiteViewLoading}
                  objectData={siteSchema}
                  detailpage={"site-detail/"}
                  handelBulkUpdateSave={handelBulkUpdateSave}
  
              />
          
          </div>
  
          <SiteFormDrawer
              objectData={siteSchema}
              objectLoading={siteObjectLoading}
              handelSubmit={handelSubmit}
              visible={false || siteGroupModal} 
              refetch={refetch} 
              setBtn={setBtn}
              data={data}
              setData={setData}
              isBtnEnable={isBtnEnable}
              isoverlay={isoverlay}
              setIsOverlay={setIsOverlay}
              loading={processloading}
              onClose={()=>setSiteGroupModal(!siteGroupModal)} 
              to={"/branch/editform"}
              from={"/user/branch"}
              title={"Site"}
          />
        
        {editGridColumn?
              <EditColumn 
                objectType={objectType.Site} 
                visible={editGridColumn} 
                onClose={()=>dispatch(setEditGridColumn(false))}
                properties = {siteSchema}
                propertiesRefetch = { async()=>{await refetchAllSiteFields(); await siteObjectRefetch(); }}
                loading = {loading}
                disable = {updateSiteViewLoading}
                refetchView = { async()=>{
                  await allSiteViewRefetch();
                  await siteObjectRefetch();
                }}
                view = {allSiteViewData?.getsiteViews?.response?.find((e)=>e._id==sessionStorage.getItem("selectedViewId"))?.viewFields || siteSchema}
                updateRenderedView = {updateSiteView}
                
              />
            : null
          }
  
          </React.Fragment>
      );
    }

}