import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Popover, Skeleton } from 'antd';
import {faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { PhoneOutlined,  FormOutlined, MessageOutlined, UserAddOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@src/components/spinner';
import { useSelector } from 'react-redux';
import { AgencyObjectQuery } from '@src/util/query/agency.query';
import { updateAgencyMutation } from '@src/util/mutation/agency.mutation';
import { GenerateFields } from '@src/util/generateFields/generateFields';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';



export const AgencyDetailPageLeftSideBar = ({agency, loading, setIsFieldChanged, saveUpdate, setSaveUpdate, refetch, isFieldChanged, showDetails})=>{
    
    const {data:agencyObject, loading: agencyObjectLoading, refetch: siteGroupObjectRefetch} = useQuery(AgencyObjectQuery);
    const navigate = useNavigate();

    const [agencySchema, setAgencySchema] = useState([]);
    useEffect(()=>{
        if(agencyObject?.getAgencyObject?.response){
            setAgencySchema(agencyObject?.getAgencyObject?.response?.map((object)=>(
                {
                    label: object?.propertyDetail?.label,
                    name: object?.propertyDetail?.label?.toLowerCase().replace(/\s/g,""),
                    fieldType: object?.propertyDetail?.fieldType,
                    property: object,
                }
            )));
           
        }
    },[agencyObject?.getAgencyObject?.response]);

    const dispatch = useDispatch();
    const param = useParams();
    const userDetail = useSelector(state=>state?.userAuthReducer?.authenticatedUserDetail);


    const [edit, setEdit] = useState(false);

    const [field, setField] = useState([]);

    const handelDataValue = ({name, value})=>{
        if(name){
            if(value){
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value}])
                }
            }else{
                // setField(field?.filter(f=>f.name!==name));
                
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value:''
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value:''}])
                }
            }
        }
    }

    
    useEffect(()=>{
        if(Object.values(agency)?.length>0 && agencySchema?.length>0){
            const localFeed = agencySchema?.map((schema)=>{

                const {name} = schema;
                return {name, value: agency[name] || agency?.metadata[name]}
                
            });
            setField([...localFeed]);
        }
    },[agencySchema, agency]);

    
    useEffect(()=>{
        if(setIsFieldChanged){

            setIsFieldChanged(false);
            setEdit(false);
        }
    },[loading, setIsFieldChanged]);

       
    useEffect(()=>{
        if(saveUpdate){
            handelUpdateAgency();
        }
    },[saveUpdate]);

    const [updateAgency, {loading: updateAgencyLoading}] = useMutation(updateAgencyMutation);

    const handelUpdateAgency = async()=>{
        try{
            
            let schemaFields = [];

            field?.map((field)=>{
                if(field.name==="agencyname" ){
                    schemaFields.push(field);
                }
                else{
                    schemaFields.push({...field, metadata:1})
                }
            });


            await updateAgency({
                variables:{
                    input:{
                        _id: param?.id,
                        properties: schemaFields
                    }
                }
            });

            setEdit(false);
            setSaveUpdate(false);
            await refetch();

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Agency  was updated successfully"
            }));
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }   
    }

    
    useEffect(()=>{
        
        if(Object.values(agency)?.length>0 && agencySchema?.length>0){
            const localFeed = agencySchema?.map((schema, index)=>{
                
                return {name: schema?.name, value: agency[schema?.name]}
                
            });
            const isEqual = localFeed.every((local)=>field.find((f)=> {
                if(f?.name==local?.name && ( Array.isArray(f?.value) || Array.isArray(local?.value) )){

                    if(f?.value?.length!=local?.value?.length){
                        return false;
                    }else{
                        return f?.value?.every((value, index)=> value==local?.value[index])
                    }

                }
                else if(f.name==local.name && f.value?.toLowerCase()==local.value?.toLowerCase()){
                    return true;
                }else{
                    return false;
                }
            }));

            setIsFieldChanged(!isEqual);
        }

    },[field]);


    return(
        <div className='sidebar-wrapper' >
            <div className='leftsidebar'>

                <div className='side-intro'>
                    {!loading && Object.keys(agency)?.length>0 ?
                    <>
                        
                        
                        
                        <div className='text-head' style={{width:'100%', textAlign:'center'}}>
                            <div className='text-title' style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%', textAlign:'center'}}>

                                <span span style={{width:'100%'}}>
                                    {agency?.agencyname?.toUpperCase()}
                                </span>
                                <Popover
                                    overlayClassName='notePopover'
                                    placement='right'
                                    content={
                                        <div className='popover-data'>
                                            <div className="popoverdataitem" onClick={()=>setEdit(!edit)}>
                                                {
                                                edit?
                                                    "Cancel Edit"
                                                :
                                                    "Edit"
                                                }
                                            </div>
                                            <div className="disabled popoverdataitem" >
                                               Customise View
                                            </div>
                                        </div>
                                    }
                                >
                                    <FontAwesomeIcon style={{cursor:'pointer'}} icon={faEllipsisV}/>
                                </Popover>
                            </div>

                            <div className='text-subtitle'>

                                <div style={{fontSize:'1em', marginBottom:'22px', marginTop:'10px'}}>
                                    <FontAwesomeIcon icon={faLocationDot}/> &nbsp; {agency?.address} 
                                </div>   
                            
                                <div className="activity-btn-grp"  style={{justifyContent:'center'}}>
                                    
                                    <Popover
                                        content={"Make a phone call"}
                                    >
                                        <span>
                                            <button className='disabled-btn'>
                                                <PhoneOutlined />
                                            </button>
                                            <span className='tiny-text disabled'>Call</span>
                                        </span>
                                    </Popover>

                                    
                                    <Popover
                                        content={"Start conversation"}
                                    >
                                        <span>
                                            <button className='disabled-btn'>
                                                {/* <FontAwesomeIcon icon={faComment} /> */}
                                                <MessageOutlined/>
                                            </button>
                                            <span className='tiny-text disabled'>Chat</span>
                                        </span>
                                    </Popover>


                                    <Popover
                                        content={"Create a note"}
                                    >
                                        <span>

                                            <button>
                                                <FormOutlined icon={faPenToSquare} />
                                            </button>
                                            <span className='tiny-text'>Note</span>
                                        </span>
                                    </Popover>


                                    
                                    <Popover content={"Follow this"} >
                                        <span>
                                            <button className='disabled-btn'>
                                                {/* <FontAwesomeIcon icon={faUserPlus} /> */}
                                                <UserAddOutlined />
                                            </button>
                                            <span className='tiny-text disabled'>Follow</span>
                                        </span>
                                    </Popover>
                                </div>

                            </div>
                        </div>
                    </>
                    : 
                    <div className='skeleton-custom'>

                    <Skeleton.Avatar active size={69} />
                    <Skeleton className='text-head' active/>
                    </div>
                    }
                </div>

                

                
            </div>
            

                {agencyObjectLoading || updateAgencyLoading?
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Spinner/>
                    </div>

                    :
                    
                    agencySchema?.map((schema, index)=>{
                        if(schema?.name?.toLowerCase().replace(/\s/g,"")=="agencyname"){
                            
                            if(edit==true){
                                 
                                return (
                                    <GenerateFields
                                        label = {schema?.label}
                                        name = {schema?.name}
                                        fieldType= {schema?.fieldType}
                                        handelDataValue = {handelDataValue}
                                        value={ field?.find((f)=>schema?.name==f.name)?.value }
                                        property = {schema?.property}
                                    />
                                );
                            
                            }else{

                                return(
                                    <div className='fieldView'>
                                        <div>{schema?.name}</div>
                                        <div>
                                            {agency[schema?.name?.toLowerCase().replace(/\s/g,"")]}
                                        </div>
                                    </div>
        
                                )
                            }
                        }
                    })
                }

                <div className="btm-border"></div>

                
                {agencyObjectLoading || updateAgencyLoading?
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Spinner/>
                    </div>
                    :
                    agencySchema.map((schema)=>( 
                    edit?
                        schema?.name?.toLowerCase().replace(/\s/g,"")!=="agencyname"?
                        
                        (
                            <GenerateFields
                                label = {schema?.label}
                                name = {schema?.name}
                                fieldType= {schema?.fieldType}
                                handelDataValue = {handelDataValue}
                                value={ field?.find((f)=>schema?.name==f.name)?.value }
                                property = {schema?.property}
                            />
                        )
                        : null

                    :
                        schema?.name?.toLowerCase().replace(/\s/g,"")!=="agencyname"?
                            (
                                <div className='fieldView'>
                                    <div>{schema?.label}</div>
                                    <div style={{textAlign:'right', textWrap:'pretty', paddingLeft:'1px'}}>
                                        {
                                           schema?.fieldType=="multiCheckbox"?
                                           agency[schema?.name?.toLowerCase().replace(/\s/g,"")]?.join(", ")
                                           :
                                            agency[schema?.name?.toLowerCase().replace(/\s/g,"")]?.toString()
                                        }
                                    </div>
                                </div>
    
                            )
                        : null
                        
                    ))
                }
               
        </div>
    );
}