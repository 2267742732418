import { useRef, useEffect } from 'react';

export const VideoThumbnail = ({ file }) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        video.addEventListener('loadeddata', () => {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            canvas.width = video.videoWidth / 2;
            canvas.height = video.videoHeight / 2;
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
        });
    }, [file]);

    return (
        <>
            <video ref={videoRef} src={URL.createObjectURL(file)} style={{ display: 'none' }} />
            <canvas ref={canvasRef}></canvas>
        </>
    );
};
