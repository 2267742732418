import './assets/default.css';
import 'react-resizable/css/styles.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-quill/dist/quill.snow.css';
import {BrowserRouter, Route, Routes, useNavigate, Navigate} from 'react-router-dom';
import { Login } from "./pages/login";
import { DefaultLayout } from "./layout/defaultLayout";
import { Employee } from "./pages/employee";
import { Branch } from "./pages/branch";
import { EditForm } from "./pages/editBranchForm/editForm.page";
import React, { useEffect } from "react";
import { Setting } from './pages/setting/setting';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { FormView } from './pages/formView/formView';
import { BranchDetailPage } from './pages/branchDetailPage/branchDetailPage';
import { AllProperties } from './pages/allProperties/allProperties.page';
import { BranchAllPropHistory } from './pages/branchAllPropHistory/branchAllPropHistory.page';
import { SiteGroup } from './pages/sitegroup/sitegroup.page';
import { EditEmployeeForm } from './pages/employee/editEmployeeForm/editEmployeeForm';
import { EmployeeDetailPage } from './pages/employeeDetailPage/employeeDetail.page';
import { EmployeeDetailViewPage } from './pages/employeeDetailPage/employeeDetailView.page';
import { EmployeeAllPropHistory } from './pages/employeeAllPropHistory/employeeAllPropHistory';
import { PropertySetting } from './pages/setting/dataFields/propertySetting';
import { User } from './pages/setting/userSetting/user';
import { routes } from './util/routes/routes';
import { UserRole } from './pages/setting/userRole/userRole';
import { Password } from './pages/login/password';
import { ClassicLogin } from './pages/login/login';
import { PrivateRoutes } from './util/routes/private.routes';
import { ApolloProvider, useLazyQuery, useQuery } from '@apollo/client';
import { privateClient, publicClient } from './config/apollo';
import { useErrorBoundary } from './util/errorBoundary/errorboundary';
import { ErrorFallback } from './util/errorFallback/errorFallback';
import { isLoginCheckQuery } from './util/query/user.query';
import { UnAuthroizedAccess } from './pages/unAuthroizedAccess/unAuthroizedAccess.page';
import { UserAccess } from './pages/setting/userAccess/userAccess.page';
import { Join } from './pages/join/join';
import { UserDetailPage } from './pages/setting/userDetail/userDetail.page';
import { useDispatch } from 'react-redux';
import { setNotification } from './middleware/redux/reducers/notification.reducer';
import { UserPerference } from './pages/setting/userPerference/userPerference';
import { SkillSetting } from './pages/setting/skillsetting/skill.setting';
import { ModuleForms } from './pages/setting/moduleForms/moduleForms';
import { Module } from './pages/setting/module/module';
import { ModuleDocuments } from './pages/setting/moduleDocuments/moduleDocuments';
import { BillingServices } from './pages/setting/billing/billingservice/billingservice';
import { PaymentMethod } from './pages/setting/billing/paymentMethod/paymentMethods';
import { BillingNotification } from './pages/setting/billing/billingNotification/billingNotification';
import { Site } from '@src/pages/site/site.page';
import { SiteGroupDetailPage } from './pages/sitegroup/sitegroupDetailPage/sitegroupDetail.page';
import { PayLevel } from './pages/setting/finanace/paylevel/paylevel';
import { ShiftType } from './pages/setting/finanace/shiftType/shiftType';
import { PayTable } from './pages/setting/finanace/payTable/payTable';
import { PayBillColumn } from './pages/setting/finanace/paybillcolumn/payBillColumn';
import { CustomerPage } from './pages/customer/customer.page';
import { CustomerDetailPage } from './pages/customer/customerDetailPage/customerDetail.page';
import { AgencyPage } from './pages/agency/agency.page';
import { AgencyDetailPage } from './pages/agency/agencyDetailPage/agencyDetail.page';
import { SitePage } from './pages/site/site.page';
import { SiteDetailPage } from './pages/site/siteDetailPage/siteDetail.page';
import { SchedulePage } from './pages/schedule/schedule.page';
import { TaskPage } from './pages/task/task.page';
// this manage the external logs that is BOC
import { ExternalSiteLog } from './pages/externalSiteLogPage/externalSiteLog.page';
import { FilePreview } from './pages/filePreview/filePreview';
import { ShareDocument } from './pages/shareDocument/shareDocument';
import { ExternalFilePreview } from './pages/externalFilePreview/externalFilePreview';
import { ReportDataFieldSetting } from './pages/setting/reportDataFields/reportpropertySetting';
import { ReportTemplatePage } from './pages/setting/reportTemplate/reportTemplate.page';
import { Premiums } from './pages/setting/finanace/premiums/premiums';
import { ScheduleEvents } from './pages/setting/scheduleEvents/scheduleEvents';
import { TimelinePage } from './pages/timeline/timeline.page';
// HolidayFeed./pages/filePreview/filePreview



function App() {



  const dispatch = useDispatch();


  useEffect(()=>{
    dispatch(setNotification({}));
    setInterval(() => {  
      if (navigator.onLine) {
       
      } else {
        console.log("offline");
      }
    }, 100);

  },[]);
  
  const {notificationToast} = useSelector(state => state.notificationReducer);
  const [api, contextHolder] = notification.useNotification();

  useEffect(()=>{
    if(Object.keys(notificationToast).length > 0){
      if(notificationToast?.error){

        api.error({
          message:notificationToast.message,
          placement: notificationToast?.placement || "top",
          className: 'notification-without-close',
        });

      }else{
        api.success({
          message:notificationToast.message,
          placement: notificationToast?.placement || "top",
          className: 'notification-without-close',
        });
      }
      dispatch(setNotification({}));
    }
  },[notificationToast]);

  const hasError = useErrorBoundary();

  useEffect(() => {
    // Check if the browser supports the Notification API
    if (!('Notification' in window)) {
      console.error('This browser does not support notifications.');
      return;
    }

    // Request permission for notifications
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      } else {
        console.error('Notification permission denied.');
      }
    });


  }, []);


  
  //   // useEffect to redirect the user from login page if he already registered
  // useEffect(()=>{
  //     if(localStorage.getItem('authToken')){
  //         window.location="/user/employee";
  //     }
  // },[]);

  return (
    hasError?
    <ErrorFallback/>
    :
    <>
    {contextHolder}
    <BrowserRouter>

      {/* public routes */}
      <ApolloProvider client={publicClient}>
        <Routes>
          {localStorage.getItem('authToken') ? <Route path='/' element={<Navigate to={"/user/employee"} />} /> :
          <Route path='/' element={<Login/>} /> }
          <Route path="/pwd" element={<Password/>} />
          <Route path="/site/:siteId/:siteName/log" element={<ExternalSiteLog/>} />
          <Route path="/join/:employeeId" element={<Join/>} />
          <Route path="/preview/:fileId" element={<ExternalFilePreview />} />
        </Routes>
      </ApolloProvider>


      {/* private Routes */}
      <ApolloProvider client={privateClient}>

        <Routes>
          {/* Error Fall back route */}
          <Route path="/error" element={<UnAuthroizedAccess/>}/>
          
          {/* Error Fall back route terminated*/}


          <Route path='/formview' element={<FormView/>} />
          <Route path='/branch/editform' exact element={<EditForm />} />
          <Route path='/employee/editform' exact element={<EditEmployeeForm />} />

          {/* private routes */}
          <Route path='/user/' element={<PrivateRoutes><DefaultLayout/></PrivateRoutes>}>

            <Route path='perference' exact element={<UserPerference/>}/>
            
            <Route path='schedule' exact element={<SchedulePage/>}/>

            <Route path='preview/:filename' exact element={<FilePreview />} />
            <Route path='share/:fileId' exact element={<ShareDocument />} />

            <Route path='branch' exact element={<Branch />}/>
            <Route path='branch-detail/:id' element={<BranchDetailPage/>} />
            <Route path='branch-detail/:id/:edit?' element={<BranchDetailPage/>} />
            <Route path='branch-detail/:id/tab/:tab?' element={<BranchDetailPage/>} />
            <Route path='branch-detail/:id/tab/:tab?/:folderId?' element={<BranchDetailPage />} />

            <Route path='prophistory' element={<BranchAllPropHistory/>} />

            <Route path='employee' element={<Employee/>} />
            <Route path='employee-detail/:id/:edit?' element={<EmployeeDetailPage/>} />
            <Route path='employee-detail/:id/tab/:tab?/:documentId?' element={<EmployeeDetailPage/>} />
            <Route path='employee-detail-view/:id' element={<EmployeeDetailViewPage/>} />
            <Route path='employee-prop-history/:id' element={<EmployeeAllPropHistory/>} />
            

            <Route path='allproperties' element={<AllProperties/>} />
            
            {/* site group */}
            <Route path='sitegroup' element={<SiteGroup/>} />
            <Route path='sitegroup-detail/:id' element={<SiteGroupDetailPage/>} />

            {/* site */}
            <Route path='site' element={<SitePage/>} />
            <Route path='site-detail/:id/:edit?' element={<SiteDetailPage/>} />
            <Route path='site-detail/:id/tab/:tab/:operationTab?' element={<SiteDetailPage/>} />
            <Route path='site-detail/:id/tab/:tab?/child/:folderId?' element={<SiteDetailPage/>} />


            {/* Customer */}
            <Route path='customer' element={<CustomerPage/>} />
            <Route path='customer-detail/:id' element={<CustomerDetailPage/>} />
            <Route path='customer-detail/:id/:edit?' element={<CustomerDetailPage/>} />


            {/* agency */}
            <Route path='agency' element={<AgencyPage/>} />
            <Route path='agency-detail/:id' element={<AgencyDetailPage/>} />
            <Route path='agency-detail/:id/:edit?' element={<AgencyDetailPage/>} />


            {/* Task */}
            <Route path='task' element={<TaskPage/>} />

            <Route path='timeline' element={<TimelinePage/>} />

             
          </Route>

          {/* setting navigation layout under hte main navigation*/}
          <Route path={routes.setting} element={<PrivateRoutes><Setting /></PrivateRoutes>}  >
            <Route index element={<User/>} />
            <Route path={routes.propertySetting} exact element={<PropertySetting/>} />
            <Route path={routes.docSetting} exact element={<ModuleDocuments/>} />
            <Route path={routes.addUser} element={<User/>} />
            <Route path={routes.userRole} element={<UserRole/>} />
            <Route path={routes.userAccess} element={<UserAccess/>} />
            <Route path={"user/:employeeId"} element={<UserDetailPage/>} />
            <Route path={routes.forms} element={<ModuleForms/>} />

            <Route path={routes.employeeEditForm} exact element={<EditEmployeeForm />} />
            <Route path={routes.branchEditForm} exact element={<EditForm />} />
            <Route path={routes.editskill} exact element={<SkillSetting/>} />

            {/* objects */}
            <Route path={routes.module} exact element={<Module/>} />

            {/* finance */}

            <Route path={routes.paybillcol}  element={<PayBillColumn/>} />
            <Route path={routes.paylevel}    element={<PayLevel/>} />
            <Route path={routes.shifttype}   element={<ShiftType/>} />
            <Route path={routes.paytable}    element={<PayTable/>} />
            <Route path={routes.premium}    element={<Premiums/>} />

            {/* report management */}
            <Route path={routes.reportField}   element={ <ReportDataFieldSetting/> }  />
            <Route path={routes.reportTemplates} element={<ReportTemplatePage />} />
            <Route path={routes.systemReport} />
            <Route path={routes.scheduleEvent} element={<ScheduleEvents />} />

            {/* billing */}

            <Route path={routes.service} exact element={<PrivateRoutes><BillingServices/></PrivateRoutes>}/>
            <Route path={routes.payment} exact element={<PaymentMethod/>}/>
            <Route path={routes.billingNotification} exact element={<BillingNotification/>}/>
            
          </Route>
          
        </Routes>

      </ApolloProvider>


    </BrowserRouter>
    </>
  );
}

export default App;
