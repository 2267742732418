import dayjs from "dayjs";
// import 'dayjs/locale/en-gb';
import { useEffect, useRef, useState } from "react";
import { CaretDownFilled, UserOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Alert, Avatar, DatePicker, Input, Modal, Popover, Select, Spin, Tag } from "antd";
import { GetEmployeeRecord } from "@src/util/query/employee.query";
import { getPayLevelQuery } from "@src/util/query/paylevel.query";
import { shiftTypeQuery } from "@src/util/query/shiftType.query";
import Spinner from "@src/components/spinner";
import { newScheduleTimesMutation } from "@src/util/mutation/scheduleTimes.mutation";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { updateScheduleMutation } from "@src/util/mutation/schedule.mutation";
import { getScheduledJobById } from "@src/util/query/schedule.query";
import { UpdateScheduleByField } from "@src/util/mutation/schedule.mutation";
import { getEmployeeForScheduleSearchQuery } from "@src/util/query/employee.query";
import { CheckFailureModal } from "../../checkFail.model";
import { newScheduleCheckMutation } from "@src/util/mutation/scheduleChecks.mutation";
import { scheduleCheckFailureList } from "@src/util/scheduleCheckFailureList";
import { deleteScheduleByScheduleIdMutation } from "@src/util/mutation/schedule.mutation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
// dayjs.locale('en-gb');

export const EditDutyTimes = ({scheduleId, close})=>{
    
    const {data: scheduledJobData, refetch: scheduleJobRefetch, loading: scheduledJobLoading} = useQuery(getScheduledJobById,{
        variables: {
            jobId: scheduleId
        },
        fetchPolicy: 'cache-and-network',
        skip: !scheduleId
    });
    // all emp data 
    const {data: employeeData, loading: employeeDataLoading, refetch} = useQuery(GetEmployeeRecord,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        },
        fetchPolicy: 'cache-and-network'
    });

    // duty type and hour type
    const {data: dutyTypeData, loading: dutyTypeLoading, refetchDutyType} = useQuery(getPayLevelQuery,{
        fetchPolicy: 'network-only',
    });
    const {data: hourTypeData, loading: hourTypeDataLoading, refetch: refetchHourType} = useQuery(shiftTypeQuery);

    const [newScheduleOperationTime, {loading: newScheduleLoading}] = useMutation(newScheduleTimesMutation);
    const [updateSchedule, {loading:updateScheduleLoading}] = useMutation(updateScheduleMutation);
    const [updateScheduleField, {loading:updateScheduleFieldLoading}] = useMutation(UpdateScheduleByField)

    const [scheduleData, setScheduleData] = useState({});
    
    useEffect(()=>{
        if(scheduledJobData?.getScheduledJobById?.response && !scheduledJobLoading){
            setScheduleData({...scheduledJobData?.getScheduledJobById?.response});
        }
    },[scheduledJobData?.getScheduledJobById?.response, scheduledJobLoading]);

    const [payDetail, setPayDetail] = useState({});

    // useEffect(()=>{
    //     if(scheduleData && Object.keys(scheduleData)?.length>0){
            
    //         const payBillInput = {

    //         }
    //         console.log(scheduleData?.assignedEmployeeDetail?.metadata?.payrate, "payratepayrate", scheduleData, scheduleData?.assignedEmployee);
            
            
    //     }
    // }, [scheduleData]);




    const [operationalAssignedEmployee, setOperationalAssignedEmployee] = useState();
    const [operationalDutyType, setOperationalDutyType] = useState();
    const [operationalHourType, setOperationalHourType] = useState();
    const [operationalStartDateTime, setOpertionalStartDateTime] = useState();
    const [operationalEndDateTime, setOpertionalEndDateTime] = useState();

    
    useEffect(()=>{
        console.log(scheduleData, "scheduleDatascheduleData", operationalAssignedEmployee)
    }, [scheduleData,operationalAssignedEmployee]);
    
    useEffect(()=>{
        if(!employeeDataLoading && !dutyTypeLoading && !hourTypeDataLoading && !newScheduleLoading && !updateScheduleLoading && !scheduledJobLoading && !hourTypeDataLoading) {
        
            if(scheduleData?.scheduletimesDetail){
                const operationTime = scheduleData?.scheduletimesDetail;

                
                setOperationalAssignedEmployee(operationTime?.operationalAssignedEmployee);
                setOperationalDutyType(operationTime?.operationalDutyType);
                setOperationalHourType(operationTime?.operationalHourType);

                setOpertionalStartDateTime(dayjs(operationTime?.operationalStartDate).format("DD/MM/YYYY HH:mm"));
                setOpertionalEndDateTime(dayjs(operationTime?.operationalEndDate).format("DD/MM/YYYY HH:mm"));

            }else{
                
                if(scheduleData?.assignedEmployee){
                    setOperationalAssignedEmployee(scheduleData?.assignedEmployee);
                }
                if(scheduleData?.dutyTypeId){
                    setOperationalDutyType(scheduleData?.dutyTypeId);
                }
                if(scheduleData?.hourTypeId){
                    setOperationalHourType(scheduleData?.hourTypeId);
                }
                if(scheduleData?.firstDate){
                    console.log(dayjs(scheduleData?.firstDate), "scheduleData?.firstDate")
                    setOpertionalStartDateTime(dayjs(scheduleData?.firstDate));
                }
                if(scheduleData?.secondDate){
                    setOpertionalEndDateTime(dayjs(scheduleData?.secondDate));
                }else{
                    setOpertionalEndDateTime(dayjs(scheduleData?.firstDate));
                }

            }

        }
    },[scheduleData, employeeDataLoading , dutyTypeLoading, hourTypeDataLoading, newScheduleLoading, updateScheduleLoading, scheduledJobLoading]);
    
    const [checkFailVisible, setCheckFailVisible] = useState(false);
    const [failureChecks, setFailureChecks] = useState(null);

    const [allowedSkilledForSpecificArea, setAllowedSkillsForSpecificArea] = useState([]);
    useEffect(()=>{
        if(scheduleData?.siteSkills?.length>0){
            setAllowedSkillsForSpecificArea(scheduleData?.siteSkills?.filter((areaSkill)=>areaSkill?.areaId==scheduleData?.siteAreaDetail?._id && areaSkill?.status)?.map((skill)=>skill?.skillId))
        }
    },[scheduleData?.siteSkills, scheduleData?.siteAreaDetail]);
    
    // check employee from search if it is suitable or not for specific job
    const checkEmployeeIfSuitable = (emp)=>{
        
        const empHoliday = emp?.employeeHolidayDetail?.find((eh)=>dayjs(eh?.holidayDate).format("DD/MM/YYYY")===dayjs(scheduleData?.firstDate).local().format("DD/MM/YYYY"))

        const empHolidayCheck = empHoliday && Object.keys(empHoliday)?.length>0? true: false;

        // if site block or not
        let blockedSite = false;
        if(emp?.blockedSites?.length>0){
            blockedSite = emp?.blockedSites?.find((bs)=>bs?.siteId==scheduleData?.site?._id)? true: false;
        }

        // branch check
        const isBranchExist = emp?.branch?.find((b)=>(b?._id==(scheduleData?.site?.branchDetail?._id || scheduleData?.branchId)));
        console.log(scheduleData?.site?.branchDetail?._id, "scheduleDatascheduleData", scheduleData?.branchId, emp?.branch );
        const branchCheck = isBranchExist? false : true;

        //============ rest period check
        const date = dayjs(scheduleData?.date, "DD/MM/YYYY")?.format("DD/MM/YYYY");
        const dateWithPrevRestH = dayjs(date+" "+scheduleData?.shiftStartTime, "DD/MM/YYYY HH:mm").subtract(8, 'hour');
        // const specificArea = emp?.schedules?.find((schedule)=>schedule?.siteAreaId==area?._id);
        const specificArea = emp?.schedules[emp?.schedules?.length-1];
       
        const restPeriodCheck = specificArea && specificArea?.schedule && Object.keys(specificArea?.schedule)?.length>0 ? dayjs(specificArea?.scheduleTimes[0]?.operationalEndDate).isSame(dateWithPrevRestH) || dayjs(specificArea?.scheduleTimes[0]?.operationalEndDate).isBefore(dateWithPrevRestH) ? false : true :false
        //============ rest period check terminated
        
        //=================== duty check / experience check
        
        const trainingCheck= emp?.trainedEmployeeOnSite?.find((te)=>te?.siteId==scheduleData?.site?._id)? false: true

        //=================== duty check / experience check terminated

        //================== skill check 
        const skillCheck = allowedSkilledForSpecificArea?.length==0? false : !allowedSkilledForSpecificArea.every((areaSkill)=> emp?.employeeskills?.find((empSkill)=>empSkill?.skill==areaSkill && empSkill?.isArchive!=true));
        //================== skill check terminated
        
        //======================= visa expiry check
        const visaExpiryCheck = emp?.metadata?.requirevisa=="Yes"? 
        emp?.metadata?.visaexpirydate? 
        dayjs(emp?.metadata?.visaexpirydate, "YYYY/MM/DD").isAfter(dayjs())? false: true
        : 
        // this check will be true if visa required and date is empty
        true  : false
        //======================= visa expiry check terminated

        // skill expiry check
        let skillExpiryCheck = false;
        const matchedSkill = emp?.employeeskills?.filter((empSkill)=> 
            allowedSkilledForSpecificArea?.find((skill)=>skill==empSkill?.skill) && empSkill?.isArchive!=true)?.
            filter((skill)=> skill?.fields?.find((f)=>f?.name=="expirydate"))
            .map((skill)=> {
                const isExist = skill?.fields?.find((f)=>f?.name=="expirydate");
                if(isExist){
                    return isExist.value;
                }
            });
            
        if(matchedSkill?.length>0){
            skillExpiryCheck = matchedSkill?.every((date)=> dayjs(date).local().isAfter(dayjs())) ? false : true
        }
        
        if(empHolidayCheck || blockedSite || restPeriodCheck || skillCheck || trainingCheck || skillExpiryCheck || visaExpiryCheck || branchCheck){
            setFailureChecks({empHolidayCheck, blockedSite, restPeriodCheck, skillCheck, trainingCheck, skillExpiryCheck, visaExpiryCheck, branchCheck, employeeData: {...emp, site:scheduleData?.site?._id}});
            setCheckFailVisible(true);
            setSearch("");
            
        }else{
            setSearch("");
            setOperationalAssignedEmployee({_id:emp?._id, isSuitable: 0, isFromSearch:1,  emp})
        }
        
    };


    const dispatch = useDispatch();
  
    // Function to disable all dates except the two enabled ones
    const disabledDate = (current) => {
        const enabledDates = [dayjs(scheduleData?.firstDate), 
            dayjs(scheduleData?.firstDate).add('1','day'),
        ];
        return !enabledDates.some((date) => current.isSame(date, "day"));
    };

    const [newScheduleCheck, {loading:newScheduleCheckLoading}] = useMutation(newScheduleCheckMutation);

    const handelScheduleOpertaionTime = async()=>{
        try{
            const startDateTime = operationalStartDateTime.format("DD/MM/YYYY HH:mm");
            const endDateTime = operationalEndDateTime.format("DD/MM/YYYY HH:mm");
            const operationalTimeInput = {
                siteId: scheduleData?.site?._id,
                scheduleId: scheduleData?._id,
                operationalStartDate: dayjs(operationalStartDateTime, "DD/MM/YYYY HH:mm"),
                operationalStartTime:  startDateTime?.split(" ")[1],
                operationalEndDate: dayjs(operationalEndDateTime, "DD/MM/YYYY HH:mm"),
                operationalEndTime: endDateTime?.split(" ")[1],
                operationalDutyType: operationalDutyType,
                operationalHourType: operationalHourType,
                operationalAssignedEmployee: operationalAssignedEmployee?._id
            };

            await newScheduleOperationTime({
                variables:{
                    input: operationalTimeInput
                }
            });

            // set to unconfirm and assign employee
            await updateSchedule({
                variables:{
                    input:{
                        _id: scheduleId,
                        status: "2",
                        assignedEmployee: operationalAssignedEmployee?._id
                    }
                }
            }); 

            const failureIfAny = failureChecks && Object.keys(failureChecks)?.filter((check)=>failureChecks[check]===true)?.map((check)=> scheduleCheckFailureList[check]?.description);
            if(failureIfAny?.length>0){
                await newScheduleCheck({
                    variables:{
                        input:{
                            scheduleId: scheduleId,
                            employeeId: operationalAssignedEmployee?._id,
                            check: failureIfAny,
                            date: dayjs(),
                        }
                    }
                });
            }    

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Operation time was updated successfully"
            }));

            await scheduleJobRefetch();

        }catch(err){
            console.log(err);
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while updating the operation time"
            }));
        }
    }

    const [getEmployeeOnSearch, {data: empSearchData, loading: empSearchDataLoading} ]= useLazyQuery(getEmployeeForScheduleSearchQuery);
    const [searchResult, setSearchResult] = useState([]);
    
    
    useEffect(()=>{
        if(empSearchData?.getEmployeeForScheduleSearch?.response){
            setSearchResult(empSearchData?.getEmployeeForScheduleSearch?.response);
        }
    },[empSearchData?.getEmployeeForScheduleSearch?.response]);
    
    const [search, setSearch] = useState("");
    useEffect(()=>{
        if(search?.length>0){
            getEmployeeOnSearch({fetchPolicy: 'cache-and-network',
                variables: {
                    input: {
                        filters:  {advanceFilter: [[{
                            filter: "contain_exactly",
                            filterValue: [search],
                            operator: 'First name'
                        }]]}
                    }
                },
                fetchPolicy: 'cache-and-network'
            });
        }
    },[search]);

    
    // set width of search result
    const parentRef = useRef(null);

    const [parentWidth, setParentWidth] = useState(null);
    useEffect(() => {
        const updateParentWidth = () => {
          if (parentRef.current) {
            const width = parentRef?.current?.input?.clientWidth;
            setParentWidth(width);
          }
        };
    
        // Call the update function on initial mount and window resize
        updateParentWidth();
        window.addEventListener('resize', updateParentWidth);
    
        // Clean up the event listener on unmount
        return () => {
          window.removeEventListener('resize', updateParentWidth);
        };
    
    }, [empSearchDataLoading, search, scheduledJobLoading]);

    const [deleteSchedule, {loading:deleteScheduleLoading}] = useMutation(deleteScheduleByScheduleIdMutation)

    // pay and bill state management

    // pay state
    const [payStartDateTime, setPayStartDateTime] = useState(null);
    const [payEndDateTime, setPayEndDateTime] = useState(null);
    const [payDutyType, setPayDutyType] = useState();
    const [payHourType, setPayHourType] = useState();
    const [payEmployeeId, setPayEmployeeId] = useState();

    

    // bill state
    const [billStartDateTime, setBillStartDateTime] = useState(null);
    const [billEndDateTime, setBillEndDateTime] = useState(null);
    const [billDutyType, setBillDutyType] = useState(null);
    const [billHourType, setBillHourType] = useState(null);
    const [billCustomerId, setBillCustomerId] = useState(null);


    const handleDragStart = (event, rowId) => {
        // Set the dragged row's id in the dataTransfer object
        event.dataTransfer.setData('rowId', rowId);
        event.dataTransfer.effectAllowed = 'move';
    };


    // Function to handle drop event
    const handleDrop = (event, targetRowId) => {
        event.preventDefault();
        const draggedRowId = event.dataTransfer.getData('rowId');
        console.log(scheduleData, "drop")
        
        // Prevent cloning on the same row
        if (draggedRowId && draggedRowId !== targetRowId ) {
            // if scheduled drag over 
            if(draggedRowId==1){
                if(targetRowId==4){
                    // set pay state
                    console.log(scheduleData, dayjs(scheduleData?.firstDate).local())

                    setPayStartDateTime( dayjs(scheduleData?.firstDate) );
                    setPayEndDateTime(
                        scheduleData?.secondDate? 
                        dayjs(scheduleData?.secondDate) : 
                        dayjs(scheduleData?.date+" "+scheduleData?.shiftEndTime, "DD/MM/YYYY HH:mm")
                    );
                    setPayDutyType(scheduleData?.dutyTypeId);
                    setPayHourType(scheduleData?.hourTypeId);
                    setPayEmployeeId(scheduleData?.assignedEmployee);
                }
                else{
                    // set bill state
                    setBillStartDateTime( dayjs(scheduleData?.firstDate) );
                    setBillEndDateTime( 
                        
                        scheduleData?.secondDate? 
                        dayjs(scheduleData?.secondDate) : 
                        dayjs(scheduleData?.date+" "+scheduleData?.shiftEndTime, "DD/MM/YYYY HH:mm")

                     );
                    setBillDutyType(scheduleData?.dutyTypeId);
                    setBillHourType(scheduleData?.hourTypeId);
                    setBillCustomerId();
                }
            }
            // if opertional drag over 
            else if(draggedRowId==2){

                if(targetRowId==4){
                    // set pay state
                    setPayStartDateTime(dayjs(operationalStartDateTime, "DD/MM/YYYY HH:mm"));
                    setPayEndDateTime(dayjs(operationalEndDateTime, "DD/MM/YYYY HH:mm"));
                    setPayDutyType(operationalDutyType);
                    setPayHourType(operationalHourType);
                    setPayEmployeeId(operationalAssignedEmployee);

                }
                else{
                    // set bill state
                    setBillStartDateTime(dayjs(operationalStartDateTime, "DD/MM/YYYY HH:mm"));
                    setBillEndDateTime(dayjs(operationalEndDateTime, "DD/MM/YYYY HH:mm"));
                    setBillDutyType(operationalDutyType);
                    setBillHourType(operationalHourType);
                    setBillCustomerId();
                }

            }
            // if actual drag over on which emp clock-in clock-out
            else if(draggedRowId==3){

                if(targetRowId==4){
                    // set pay state
                    setPayStartDateTime(dayjs(scheduleData?.scheduletimesDetail?.actualStartDate));
                    setPayEndDateTime(dayjs(scheduleData?.scheduletimesDetail?.actualEndDate));
                    setPayDutyType(operationalDutyType);
                    setPayHourType(operationalHourType);
                    setPayEmployeeId(operationalAssignedEmployee);

                }
                else{
                    // set bill state
                    setBillStartDateTime(dayjs(scheduleData?.scheduletimesDetail?.actualStartDate));
                    setBillEndDateTime(dayjs(scheduleData?.scheduletimesDetail?.actualEndDate));
                    setBillDutyType(operationalDutyType);
                    setBillHourType(operationalHourType);
                    setBillCustomerId();
                }

            }

            console.log(draggedRowId, targetRowId, "draggedresult")
        }
    };

    // Function to allow dropping
    const handleDragOver = (event) => {
        event.preventDefault(); // Necessary to allow drop
    };

    const [warning, setWarningModal] = useState(false);

    
    const calculateTimeDifference = (time1, time2) => {
        // Define the start and end times
        const firstDate = dayjs(time1, 'DD/MM/YYYY HH:mm').second(0);
        const secondDate = dayjs(time2, 'DD/MM/YYYY HH:mm').second(0);

        if (firstDate.isSame(secondDate, 'day')) {
            // Case 1: Same day
            const firstDateHours = secondDate.diff(firstDate, 'hour', true);

            return {firstDateHours: firstDateHours, secondDateHours:0}

        } else {        
            // Case 2: Different days
            // 1. Calculate remaining hours in the first day (until midnight)
            const endOfFirstDay = firstDate.endOf('day') // Get the end of the first day (23:59:59)
            const hoursInFirstDay = endOfFirstDay.diff(firstDate, 'hour', true); // Convert diff to hours
          
            // 2. Calculate hours on the second day (from midnight to the second date)
            const startOfSecondDay = secondDate.startOf('day'); // Midnight of the second day
            const hoursInSecondDay = secondDate.diff(startOfSecondDay, 'hour', true); // Convert diff to hours
          
            // Display the results;
            return {firstDateHours: hoursInFirstDay, secondDateHours: hoursInSecondDay}
        }


    }

    useEffect(()=>{
        if(payStartDateTime!=null && payEndDateTime!=null){
            const {firstDateHours, secondDateHours} = calculateTimeDifference(payStartDateTime, payEndDateTime);
            console.log(firstDateHours, "firstDate", secondDateHours);
        }
    },[payStartDateTime, payEndDateTime]);

    useEffect(()=>{
        if(billStartDateTime!=null && billEndDateTime!=null){
            const {firstDateHours, secondDateHours} = calculateTimeDifference(billStartDateTime, billEndDateTime);
            console.log(firstDateHours, "firstDate", secondDateHours);
           
        }
    },[billStartDateTime, billEndDateTime]);



    return(
        
        <div className="box-shadow border-radius">

            <div style={{padding:'16px'}}>
                <div className='hr-info-title'>
                    Duty Times
                </div>
            </div>

            {employeeDataLoading || dutyTypeLoading || hourTypeDataLoading || newScheduleLoading || updateScheduleLoading || scheduledJobLoading?
                
                <div style={{display:'table', margin:'auto'}}>
                    <Spinner/>
                </div>
                :
                Object.keys(scheduleData)?.length>0?


                <table className="times" cellPadding={5}>
                    <thead>
                        <th>Type</th>
                        <th>Start</th>
                        <th>Finish</th>
                        <th>Duty Type</th>
                        <th>Hour Type</th>
                        <th>Employee</th>
                    </thead>

                    <tbody>
                        
                        <tr
                            key={1}
                            id={1}
                            draggable
                            draggedRowId={1}
                            onDragStart={(e)=>handleDragStart(e, 1)}
                            style={{cursor:'grab'}}
                        >
                            <td>Scheduled</td>
                            {/* start date and time */}
                            <td>
                                {dayjs(scheduleData?.firstDate).local().format("DD/MM/YYYY HH:mm")}
                            </td>

                            {/* finish date and time */}
                            <td>
                                {
                                    scheduleData?.secondDate?
                                    dayjs(scheduleData?.secondDate).local().format("DD/MM/YYYY HH:mm")
                                        :
                                    dayjs(scheduleData?.date+" "+scheduleData?.shiftEndTime, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm")
                                }
                            </td>

                            {/* Duty Type */}
                            <td>
                                {scheduleData?.dutyTypeDetail[0]?.name}
                            </td>

                            {/* Hour Type */}
                            <td>
                                {scheduleData?.hourTypeDetail[0]?.name}
                            </td>

                            {/* Select Employee */}
                            <td style={{display:'flex', columnGap:'5px', alignItems:'center'}}>
                                {
                                scheduleData?.assignedEmployeeDetail?
                                <>
                                    {
                                        scheduleData?.assignedEmployeeDetail?.metadata?.imageURL?
                                        <div className="employeeProfile">
                                            <Avatar size={40} src={scheduleData?.assignedEmployeeDetail?.metadata?.imageURL} />
                                        </div>

                                        :
                                        <div className="employeeProfile">
                                            <Avatar size={40} style={{fontSize:'10px'}}> <UserOutlined/> </Avatar>
                                        </div>
                                    
                                    }
                                
                                    {scheduleData?.assignedEmployeeDetail?.firstname+" "+(scheduleData?.assignedEmployeeDetail?.lastname || "")}
                            
                                </>
                                : "No employee assigned"
                                }
                            </td>
                            
                        </tr>
                        {
                            scheduleData?.scheduletimesDetail?
                            <tr
                                
                                key={2}
                                id={2}
                                draggable
                                draggedRowId={2}
                                onDragStart={(e)=>handleDragStart(e, 2)}
                                style={{cursor:'grab'}}
                            >
                                <td>Opertional</td>
                                {/* start date and time */}
                                <td>
                                    {dayjs(operationalStartDateTime).format("DD/MM/YYYY HH:mm")=="Invalid Date"? operationalStartDateTime : dayjs(operationalStartDateTime, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm")}
                                </td>

                                {/* finish date and time */}
                                <td>
                                    {dayjs(operationalEndDateTime).format("DD/MM/YYYY HH:mm")=="Invalid Date"? operationalEndDateTime : dayjs(operationalEndDateTime, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm")}
                                </td>

                                {/* Duty Type */}
                                <td>
                                    {dutyTypeData?.getPayLevel?.response?.find((dutyType)=>dutyType?._id==operationalDutyType)?.name}
                                </td>

                                {/* Hour Type */}
                                <td>
                                    {hourTypeData?.getShiftType?.response?.find((hourType)=>hourType?._id==operationalHourType)?.name}
                                </td>

                                {/* Select Employee */}
                                <td style={{display:'flex', columnGap:'5px', alignItems:'center'}}>
                                    {
                                        scheduleData?.OperationalTimeDetail?.metadata?.imageURL?
                                        <div className="employeeProfile">
                                            <Avatar size={40} src={scheduleData?.OperationalTimeDetail?.metadata?.imageURL} />
                                        </div>
                                        :
                                        <div className="employeeProfile">
                                            <Avatar size={40} style={{fontSize:'10px'}}> <UserOutlined/> </Avatar>
                                        </div>

                                    }
                                    <span>
                                        {
                                            scheduleData?.OperationalTimeDetail?.firstname 
                                        } {scheduleData?.OperationalTimeDetail?.lastname || ""}
                                    </span>
                                </td>
                                
                            </tr>
                            :
                            <tr>
                                <td>Opertional</td>

                                <td>
                                    <DatePicker
                                        className="generic-input-control"
                                        showTime
                                        showToday
                                        style={{marginTop:'-4px'}}
                                        format={"DD/MM/YYYY HH:mm"}
                                        suffixIcon={null}
                                        disabledDate={(currentDate)=> ![dayjs(scheduleData?.firstDate)].some((date) => currentDate.isSame(date, "day"))}
                                        value={operationalStartDateTime}
                                        onChange={(e, dateTimeString)=>  dateTimeString?.length>0 ? setOpertionalStartDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setOpertionalStartDateTime(null)}
                                    />
                                </td>

                                <td>
                                    <DatePicker
                                        showTime
                                        showToday
                                        suffixIcon={null}
                                        disabledDate={disabledDate}
                                        format={"DD/MM/YYYY HH:mm"}
                                        className="generic-input-control"
                                        style={{marginTop:'-4px'}}
                                        value={operationalEndDateTime}
                                        onChange={(e, dateTimeString)=>  dateTimeString?.length>0 ? setOpertionalEndDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setOpertionalEndDateTime(null)}
                                    />
                                </td>

                                <td>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Duty type"
                                        clearIcon
                                        className="custom-select schedule-section" 
                                        suffixIcon={<span className="dropdowncaret"></span>}
                                        style={{width:'100%'}}
                                        loading={dutyTypeLoading}
                                        value={operationalDutyType}
                                        onChange={(e)=>setOperationalDutyType(e)}
                                    >
                                        {dutyTypeData?.getPayLevel?.response?.map((dutyTypeData)=>(
                                            <Select.Option value={dutyTypeData?._id}>{dutyTypeData?.name}</Select.Option>
                                        ))}
                                    </Select>
                                </td>

                                <td>          
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Hour type"
                                        className="custom-select schedule-section" 
                                        suffixIcon={<span className="dropdowncaret"></span>}
                                        style={{width:'-webkit-fill-available', cursor:'none'}}
                                        loading={hourTypeDataLoading}
                                        value={operationalHourType}
                                        onChange={(e)=>setOperationalHourType(e)}
                                    >
                                        {hourTypeData?.getShiftType?.response?.map((hour)=>(
                                            <Option value={hour?._id}>{hour?.name}</Option>
                                        ))}
                                    </Select>
                                </td>

                                <td>

                                    <div style={{width:'100%'}}>
                                            
                                            {
                                                operationalAssignedEmployee?
                                                <Tag closable onClose={()=>setOperationalAssignedEmployee()} style={{marginBottom:'6px', display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%', height:'38px'}}>
                                                    <div style={{display:'flex', columnGap:'10px', alignItems:'center'}}>
                                                        <div className="employeeProfile">
                                                            <Avatar size={30} style={{background:'lightgrey'}} src={operationalAssignedEmployee?.emp?.metadata?.imageURL || <UserOutlined/>} />
                                                        </div>
                                                        <div style={{fontSize:'14px'}}>
                                                            {operationalAssignedEmployee?.emp?.firstname} {operationalAssignedEmployee?.emp?.lastname || ""}
                                                        </div>
                                                    </div>
                                                </Tag>
                                                :
                                                <Input
                                                    className="generic-input-control"
                                                    placeholder="Type to search employee"
                                                    style={{width:'100%', marginBottom:'6px'}}
                                                    suffixIcon={<div className="dropdowncaret"></div>}
                                                    onChange={(e)=>setSearch(e.target.value)}
                                                    ref={parentRef}
                                                />
                                            }
                                            {search?.length>0?
                                                <div className="moveGroupData scheduleSearchEmployee" style={{ width: parentWidth || "-webkit-fill-available", marginTop:'-3px' }}>
                                                    {
                                                        empSearchDataLoading?
                                                            <div style={{margin:'auto', display:'table', justifyContent:'center', alignItems:'center'}}>
                                                                <Spinner/>
                                                            </div>
                                                        :
                                                        searchResult?.length==0?
                                                            <div style={{fontWeight:300}}>no employee found</div>
                                                        :
                                                        searchResult?.map((result)=>{
                                                            const lastname = result?.lastname? result?.lastname : "";
                                                            const firstname = result?.firstname? result?.firstname : "";
                                                            return(
                                                                <div className="popoverdataitem" onClick={()=>checkEmployeeIfSuitable(result)} style={{padding:'10px 5px'}}>
                                                                    <div style={{display:'flex', columnGap:'12px', alignContent:'flex-start', justifyContent:'flex-start'}}>
                                                                        <div className="employeeProfile">
                                                                            <Avatar  size={40} style={{background:'lightgrey'}} src={result?.metadata?.imageURL || <UserOutlined/>} />
                                                                        </div>
                                                                        <div style={{textAlign: 'left'}}>
                                                                            <div>{firstname +" "+ lastname}</div>
                                                                            <div style={{color:'grey', fontWeight:300}}>{result?.metadata?.employmenttype} </div>
                                                                            <div style={{color:'grey', fontWeight:300, fontSize:'11px', textAlign:'left'}}>{result?.metadata?.agency[0]?.agencyname?  "["+ result?.metadata?.agency[0]?.agencyname +"]" : null }</div>
                                                                        
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        )
                                                    }

                                                </div>
                                                :null
                                            }
                                    </div>

                                </td>

                            </tr>
                        }

                        <tr  
                            
                            key={3}
                            id={3}
                            draggable={scheduleData?.scheduletimesDetail?.actualEndDate? true: false}
                            draggedRowId={3}
                            onDragStart={(e)=>handleDragStart(e, 3)}
                            style={{cursor:'grab'}}

                        >

                            <td>Actual</td>

                            <td> {scheduleData?.scheduletimesDetail?.actualStartDate? dayjs(scheduleData?.scheduletimesDetail?.actualStartDate).local().format("DD/MM/YYYY HH:mm") : "--"} </td>

                            <td> {scheduleData?.scheduletimesDetail?.actualEndDate? dayjs(scheduleData?.scheduletimesDetail?.actualEndDate).local().format("DD/MM/YYYY HH:mm") : "--"} </td>

                            <td> 
                                {scheduleData?.scheduletimesDetail?.actualStartDate? dutyTypeData?.getPayLevel?.response?.find((dutyType)=>dutyType?._id==operationalDutyType)?.name : "--"}
                            </td>

                            <td>                                     
                                {scheduleData?.scheduletimesDetail?.actualStartDate? hourTypeData?.getShiftType?.response?.find((hourType)=>hourType?._id==operationalHourType)?.name : "--"}   
                            </td>

                            <td> -- </td>

                        </tr>

                        <tr style={{ margin: '0 0 35% 0', display: 'block'}}></tr>

                        {/* pay and bill section */}
                        

                        <tr
                            
                            key={4}
                            id={4}
                            onDrop={(e)=>handleDrop(e, 4)}
                            onDragOver={scheduleData?.scheduletimesDetail?.actualEndDate? handleDragOver : ()=>setWarningModal(true)}
                        >

                            <td>Pay</td>

                            <td> 
                                
                                <DatePicker
                                    className="generic-input-control"
                                    showTime
                                    showToday
                                    style={{marginTop:'-4px'}}
                                    format={"DD/MM/YYYY HH:mm"}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    disabledDate={(currentDate)=> ![dayjs(scheduleData?.firstDate)].some((date) => currentDate.isSame(date, "day"))}
                                    value={payStartDateTime? payStartDateTime : null}
                                    suffixIcon={null}
                                    onChange={(e, dateTimeString)=> dateTimeString?.length>0 ? setPayStartDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setPayStartDateTime(null)}
                                />

                            </td>

                            <td> 
                                
                                <DatePicker
                                    className="generic-input-control"
                                    showTime
                                    showToday
                                    style={{marginTop:'-4px'}}
                                    format={"DD/MM/YYYY HH:mm"}
                                    suffixIcon={null}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    disabledDate={disabledDate}
                                    value={payEndDateTime}
                                    onChange={(e, dateTimeString)=>  dateTimeString?.length>0 ? setPayEndDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setPayEndDateTime(null)}
                                />
                                    
                            </td>

                            <td> 
                                
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    className="custom-select schedule-section" 
                                    suffixIcon={<span className="dropdowncaret"></span>}
                                    style={{width:'100%'}}
                                    loading={dutyTypeLoading}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    onChange={(e)=>setPayDutyType(e)}
                                    value={payDutyType}
                                >
                                    {dutyTypeData?.getPayLevel?.response?.map((dutyTypeData)=>(
                                        <Select.Option value={dutyTypeData?._id}>{dutyTypeData?.name}</Select.Option>
                                    ))}
                                </Select>

                            </td>
                            
                            <td> 
                                      
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    className="custom-select schedule-section" 
                                    suffixIcon={<span className="dropdowncaret"></span>}
                                    style={{width:'-webkit-fill-available', cursor:'none'}}
                                    loading={hourTypeDataLoading}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    onChange={(e)=>setPayHourType(e)}
                                    value={payHourType}
                                >
                                    {hourTypeData?.getShiftType?.response?.map((hour)=>(
                                        <Option value={hour?._id}>{hour?.name}</Option>
                                    ))}
                                </Select>
                            </td>
                            
                            <td style={{display:'flex', columnGap:'5px', alignItems:'center'}}>
                                {
                                    scheduleData?.OperationalTimeDetail?
                                    <>
                                    {
                                        scheduleData?.OperationalTimeDetail?.metadata?.imageURL?
                                        <div className="employeeProfile">
                                            <Avatar size={40} src={scheduleData?.OperationalTimeDetail?.metadata?.imageURL} />
                                        </div>
                                        :
                                        <div className="employeeProfile">
                                            <Avatar size={40} style={{fontSize:'10px'}}> <UserOutlined/> </Avatar>
                                        </div>
                                    }
                                        <span>
                                            {
                                                scheduleData?.OperationalTimeDetail?.firstname+" "+(scheduleData?.OperationalTimeDetail?.lastname || "")
                                            }
                                        </span>
                                    </>
                                    :null
                                }
                            </td>


                        </tr>

                        

                        <tr
                            
                            key={5}
                            id={5}
                            onDrop={(e)=>handleDrop(e, 5)}                            
                            onDragOver={scheduleData?.scheduletimesDetail?.actualEndDate? handleDragOver : ()=>setWarningModal(true)}

                        >

                            <td>Bill</td>
                            
                            <td> 
                                
                                <DatePicker
                                    className="generic-input-control"
                                    showTime
                                    showToday
                                    style={{marginTop:'-4px'}}
                                    suffixIcon={null}
                                    format={"DD/MM/YYYY HH:mm"}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    disabledDate={(currentDate)=> ![dayjs(scheduleData?.firstDate)].some((date) => currentDate.isSame(date, "day"))}
                                    value={billStartDateTime}
                                    onChange={ (e, dateTimeString)=> dateTimeString?.length>0 ? setBillStartDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setBillStartDateTime(null) }
                                />

                            </td>

                            <td> 
                                
                                <DatePicker
                                    className="generic-input-control"
                                    showTime
                                    showToday
                                    style={{marginTop:'-4px'}}
                                    format={"DD/MM/YYYY HH:mm"}
                                    suffixIcon={null}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    value={billEndDateTime}
                                    disabledDate={disabledDate}
                                    onChange={(e, dateTimeString)=> dateTimeString?.length>0 ? setBillEndDateTime(dayjs(dateTimeString, "DD/MM/YYYY HH:mm")) : setBillEndDateTime(null) }
                                />
                                    
                            </td>

                            <td> 
                                
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    className="custom-select schedule-section" 
                                    suffixIcon={<span className="dropdowncaret"></span>}
                                    style={{width:'100%'}}
                                    loading={dutyTypeLoading}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    onChange={(e)=>setBillDutyType(e)}
                                    value={billDutyType}
                                >
                                    {dutyTypeData?.getPayLevel?.response?.map((dutyTypeData)=>(
                                        <Select.Option value={dutyTypeData?._id}>{dutyTypeData?.name}</Select.Option>
                                    ))}
                                </Select>

                            </td>
                            
                            <td> 
                                      
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    className="custom-select schedule-section" 
                                    suffixIcon={<span className="dropdowncaret"></span>}
                                    style={{width:'-webkit-fill-available', cursor:'none'}}
                                    loading={hourTypeDataLoading}
                                    disabled={!scheduleData?.OperationalTimeDetail}
                                    onChange={(e)=>setBillHourType(e)}
                                    value={billHourType}
                                >
                                    {hourTypeData?.getShiftType?.response?.map((hour)=>(
                                        <Option value={hour?._id}>{hour?.name}</Option>
                                    ))}
                                </Select>
                            </td>
                            
                            <td style={{display:'flex', columnGap:'5px', alignItems:'center'}}>
                                
                            </td>


                        </tr>


                    </tbody>
                </table>

                :

                "Duty data is not currently available"
            
            
            }


{/* times footer */}
            <div 
                className="drawer-footer border-radius-bottom"
                style={{display:'flex', alignItems:'center', justifyContent:'space-between', margin:'-10px', marginTop:'100px'}}
            >
                <div>
                {scheduleData?.status=="3" || updateScheduleFieldLoading?
                    <button className="drawer-outlined-btn disabled-btn">Duty {scheduleData?.status=="3"? "Completed" : "Actions"}</button>

                :
                    <Popover
                        overlayClassName='notePopover'
                        content={
                            <div className='popover-data'>

                            {
                                scheduleData?.isPublished?
                                <div className="popoverdataitem"
                                    onClick={async()=>{
                                        await updateScheduleField({
                                            variables:{
                                                input:{
                                                    field: "isPublished",
                                                    value: false,
                                                    _id: scheduleData?._id,
                                                }
                                            }
                                        });
                                        await scheduleJobRefetch();
                                }}>
                                    Unpublish Duty
                                </div>
                                :
                                <div className="popoverdataitem"
                                    onClick={async()=>{
                                        await updateScheduleField({
                                            variables:{
                                                input:{
                                                    field: "isPublished",
                                                    value: true,
                                                    _id: scheduleData?._id,
                                                }
                                            }
                                        });
                                        await scheduleJobRefetch();
                                    }}
                                >
                                    Publish Duty
                                </div>

                            }

                            {
                                // set status to 1 for confirmed status
                                // if it is 1 then status will be confirm
                                scheduleData?.status=="1"?
                                    <div className="popoverdataitem"
                                    onClick={async()=>{
                                        await updateScheduleField({
                                            variables:{
                                                input:{
                                                    field: "status",
                                                    value: "2",
                                                    _id: scheduleData?._id,
                                                }
                                            }
                                        });
                                        await scheduleJobRefetch();
                                    }}>
                                        Unconfirm Duty
                                    </div>
                                :
                                // set status to 1 for confirmed status
                                // if it is 1 then status will be confirm                  
                                    <div className="popoverdataitem"
                                        onClick={async()=>{
                                            await updateScheduleField({
                                                variables:{
                                                    input:{
                                                        field: "status",
                                                        value: "1",
                                                        _id: scheduleData?._id,
                                                    }
                                                }
                                            });
                                            await scheduleJobRefetch();
                                        }}
                                    >
                                        Confirm Duty
                                    </div>
                            }

                                <div className="popoverdataitem"
                                    onClick={async()=>{
                                        await deleteSchedule({
                                            variables: {
                                                scheduleId: scheduleData?._id
                                            }
                                        });
                                        await scheduleJobRefetch();
                                        close();
                                    }}
                                >
                                    Delete Duty
                                </div>

                            </div>
                        }
                    >

                        <button  
                            // disabled={updateScheduleFieldLoading || scheduleData?.status=="3"} 
                            className={'drawer-outlined-btn'}
                        >
                            {
                                updateScheduleFieldLoading || scheduleData?.status=="3"?
                                <>
                                    Duty Actions  <Spinner/>
                                </> 
                                :
                                <>
                                 Duty Actions <CaretDownFilled />
                                </>
                            }
                        </button>

                    </Popover>
                }
                    &emsp;

                    

                </div>

                {
                    scheduleData?.scheduletimesDetail?
                    
                    <button className="drawer-filled-btn disabled-btn">Pay / Bill</button>
                    :
                    <button disabled={false}  className={false? 'disabled-btn drawer-filled-btn':'drawer-filled-btn'} 
                        onClick={handelScheduleOpertaionTime}
                    >
                        {false? <Spinner/> : "Save"}
                    </button>
                    
                }

            </div>

            <CheckFailureModal visible={checkFailVisible} save={setOperationalAssignedEmployee} failureChecks={failureChecks} close={()=>setCheckFailVisible(!checkFailVisible)} />
            <WarningModal visible={warning} close={()=>setWarningModal(false)}/>
        </div>
    );
};

const WarningModal = ({close, visible})=>{
    return(
        <Modal
            visible={visible}
            footer={null}
            closable={false}
        >

        <div className='modal-header-title'>
            <span style={{letterSpacing:'0.2px'}}> Warning </span>
            <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
        </div>

            <div className='modal-body'>
                <Alert type="error" message={"Duty is not completed yet"} showIcon/>
            </div>

        </Modal>
    );
}